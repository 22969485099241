import React, { useEffect, useState } from "react";
import {
  branchListEndPoint,
  dashboardNewopEndPoint,
  dashboardProcedurewiseEndPoint,
} from "../../service/api";
import { getAxios, postAxiosWithToken } from "../../service/apiservice";
import DatePicker1 from "../../components/DatePicker1";
import { useNavigate, useParams } from "react-router-dom";

function Branchandprocedure() {

    const navigate = useNavigate();
  const { fromDate, toDate, status_id } = useParams();


  const [value, setValue] = useState({
    startDate: fromDate,
    endDate: toDate,
  });
  const [branchDropdown, setBranchDropdown] = useState([]);
  const [Digitalmarketing, setDigitalmarketinglist] = useState();


  const selectedBranchFromLocalStorage = localStorage.getItem("dasboardbranch");

  const [body, setBody] = useState({
    branch: selectedBranchFromLocalStorage || localStorage.getItem("branch"),
    from_date: fromDate || "",
    to_date: toDate || "",
    status: status_id,
  });

  const [sorceid, setSorceId] = useState(localStorage.getItem("branchdashboard") || "");

  useEffect(() => {
    localStorage.setItem("branchdashboard", sorceid);
  }, [sorceid]);
  
  const [sorceBody, setSorceBody] = useState({
    branch: selectedBranchFromLocalStorage || sorceid,
    from_date: fromDate || "",
    to_date: toDate || "",
  });
  
  useEffect(() => {
    setSorceBody({
      branch: selectedBranchFromLocalStorage || sorceid,
      from_date: fromDate || "",
      to_date: toDate || "",
    });
  }, [selectedBranchFromLocalStorage, sorceid, fromDate, toDate]);

  


  const [data, setdata] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getAllLeadsListData();
    getBranchDropdownData();
 
  }, [body]);

  useEffect(()=>{
    sorcetype();
  },[sorceBody])


 

  const sorcetype = async () => {
    var response = await postAxiosWithToken({
      url: dashboardProcedurewiseEndPoint,
      body: sorceBody,
    });

    if (response !== null) {

    
      setDigitalmarketinglist(response.data);

    }
  };
  
  



  const getAllLeadsListData = async () => {
    var response = await postAxiosWithToken({
      url: dashboardNewopEndPoint,
      body: body,
    });

    if (response !== null) {
      setdata(response.data);
      setIsLoading(false);
    }
  };

  const getBranchDropdownData = async () => {
    var response = await getAxios({
      url: branchListEndPoint,
    });

    if (response !== null) {
      setBranchDropdown(response.data.list);
    }
  };

  const handleDateFilter = async (e) => {
    if (e.startDate === null && e.endDate === null) {
      setBody((prevState) => ({
        ...prevState,
        from_date: "",
        to_date: "",
      }));
    } else {
      setBody((prevState) => ({
        ...prevState,
        from_date: e.startDate,
        to_date: e.endDate,
      }));
    }

    if (e.startDate === null && e.endDate === null) {
      setSorceBody((prevState) => ({
        ...prevState,
        from_date: "",
        to_date: "",
      }));
    } else {
      setSorceBody((prevState) => ({
        ...prevState,
        from_date: e.startDate,
        to_date: e.endDate,
      }));
    }

    setValue(e);
  };



  return (
    <div>
     
      {status_id == 1 ? (
        <div>
          <h2>New OP</h2>
          <div className="flex gap-[10px] justify-end">
            
            <div className="h-[40px] w-[200px] max-[520px]:mt-[5px] max-[520px]:w-full    ">
              {/* <Searchbar value={body.search} handleChange={searchHandler} /> */}
            </div>
            <div className="!border !border-slate-800 !border-opacity-20 rounded-md mt-[20px] md:mt-[0px]">
              <DatePicker1 handleValueChange={handleDateFilter} value={value} />
            </div>

            <div className="max-[520px]:mt-[5px] max-[575px]:w-full max-[520px]:mb-[50px] max-[575px]:mt-4  ">
              {localStorage.getItem("branch") == 0 ? (
                <select
                  placeholder="Branch"
                  className="sm:mb-[5px] h-[40px] w-[200px] capitalize bg-white border border-gray-300 rounded-lg p-[11px] text-[15px] outline-none"
                  name="branch"
                  value={body.branch}
                  onChange={(e) => {
                    setBody((prevState) => ({
                      ...prevState,
                      branch: e.target.value,
                    }));
                  }}
                >
                  <option value={""}>--select branch--</option>
                  {branchDropdown.map((option) => (
                    <option
                      key={option.id}
                      value={option.id}
                      className="capitalize"
                    >
                      {option.branch_name}
                    </option>
                  ))}
                </select>
              ) : (
                ""
              )}
            </div>
          </div>

          <div className="w-full my-[10px] mb-[50px] overflow-x-scroll rounded-lg ">
            <table className="w-full">
              <thead className="border bg-secondary text-white">
                <tr>
                  <td className="p-[10px] min-w-[200px]">Branch Name</td>
                  <td className="p-[10px] min-w-[200px]">Total Count</td>
                  <td className="p-[10px] min-w-[200px]">Digital Count</td>
                  <td className="p-[10px] min-w-[200px]">Non Digital Count</td>
                  <td className="p-[10px] min-w-[200px]">Referral Count</td>
                </tr>
              </thead>
              <tbody>
                {isLoading ? (
                  <tr className="text-center w-full">
                    <td className="text-center border" colSpan={13}>
                      Loading...
                    </td>
                  </tr>
                ) : data.branches.length === 0 ? (
                  <tr>
                    <td className="text-center border" colSpan={13}>
                      No Data
                    </td>
                  </tr>
                ) : (
                  data?.branches?.map((item, index) => (
                    <tr key={index} className="border">
                      <td className="p-[10px]">{item.branch_name}</td>
                      <td className="p-[10px]">{item.total_count}</td>
                      <td className="p-[10px]">{item.digital_count}</td>
                      <td className="p-[10px]">{item.non_digital_count}</td>
                      <td className="p-[10px]">{item.referral_count}</td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>
      ) : status_id == 2 ? (
        <div>
          <h2>Review OP</h2>
          <div className="flex gap-[10px] justify-end">
            <div className="h-[40px] w-[200px] max-[520px]:mt-[5px] max-[520px]:w-full    ">
              {/* <Searchbar value={body.search} handleChange={searchHandler} /> */}
            </div>
            <div className="!border !border-slate-800 !border-opacity-20 rounded-md mt-[20px] md:mt-[0px]">
              <DatePicker1 handleValueChange={handleDateFilter} value={value} />
            </div>

            <div className="max-[520px]:mt-[5px] max-[575px]:w-full max-[520px]:mb-[50px] max-[575px]:mt-4  ">
              {localStorage.getItem("branch") == 0 ? (
                <select
                  placeholder="Branch"
                  className="sm:mb-[5px] h-[40px] w-[200px] capitalize bg-white border border-gray-300 rounded-lg p-[11px] text-[15px] outline-none"
                  name="branch"
                  value={body.branch}
                  onChange={(e) => {
                    setBody((prevState) => ({
                      ...prevState,
                      branch: e.target.value,
                    }));
                  }}
                >
                  <option value={""}>--select branch--</option>
                  {branchDropdown.map((option) => (
                    <option
                      key={option.id}
                      value={option.id}
                      className="capitalize"
                    >
                      {option.branch_name}
                    </option>
                  ))}
                </select>
              ) : (
                ""
              )}
            </div>
          </div>

          <div className="w-full my-[10px] mb-[50px] overflow-x-scroll rounded-lg ">
            <table className="w-full">
              <thead className="border bg-secondary text-white">
                <tr>
                  <td className="p-[10px] min-w-[200px]">Branch Name</td>
                  <td className="p-[10px] min-w-[200px]">Total Count</td>
                  <td className="p-[10px] min-w-[200px]">Digital Count</td>
                  <td className="p-[10px] min-w-[200px]">Non Digital Count</td>
                  <td className="p-[10px] min-w-[200px]">Referral Count</td>
                </tr>
              </thead>
              <tbody>
                {isLoading ? (
                  <tr className="text-center w-full">
                    <td className="text-center border" colSpan={13}>
                      Loading...
                    </td>
                  </tr>
                ) : data.branches.length === 0 ? (
                  <tr>
                    <td className="text-center border" colSpan={13}>
                      No Data
                    </td>
                  </tr>
                ) : (
                  data?.branches?.map((item, index) => (
                    <tr key={index} className="border">
                      <td className="p-[10px]">{item.branch_name}</td>
                      <td className="p-[10px]">{item.total_count}</td>
                      <td className="p-[10px]">{item.digital_count}</td>
                      <td className="p-[10px]">{item.non_digital_count}</td>
                      <td className="p-[10px]">{item.referral_count}</td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>
      ) : status_id == 3 ? (
        <div>
          <h2>Total IP</h2>
          <div className="flex gap-[10px] justify-end">
            <div className="h-[40px] w-[200px] max-[520px]:mt-[5px] max-[520px]:w-full    ">
              {/* <Searchbar value={body.search} handleChange={searchHandler} /> */}
            </div>
            <div className="!border !border-slate-800 !border-opacity-20 rounded-md mt-[20px] md:mt-[0px]">
              <DatePicker1 handleValueChange={handleDateFilter} value={value} />
            </div>

            <div className="max-[520px]:mt-[5px] max-[575px]:w-full max-[520px]:mb-[50px] max-[575px]:mt-4  ">
              {localStorage.getItem("branch") == 0 ? (
                <select
                  placeholder="Branch"
                  className="sm:mb-[5px] h-[40px] w-[200px] capitalize bg-white border border-gray-300 rounded-lg p-[11px] text-[15px] outline-none"
                  name="branch"
                  value={body.branch}
                  onChange={(e) => {
                    setBody((prevState) => ({
                      ...prevState,
                      branch: e.target.value,
                    }));
                  }}
                >
                  <option value={""}>--select branch--</option>
                  {branchDropdown.map((option) => (
                    <option
                      key={option.id}
                      value={option.id}
                      className="capitalize"
                    >
                      {option.branch_name}
                    </option>
                  ))}
                </select>
              ) : (
                ""
              )}
            </div>
          </div>

          <div className="w-full my-[10px] mb-[50px] overflow-x-scroll rounded-lg ">
            <table className="w-full">
              <thead className="border bg-secondary text-white">
                <tr>
                  <td className="p-[10px] min-w-[200px]">Branch Name</td>
                  <td className="p-[10px] min-w-[200px]">Total Count</td>
                  <td className="p-[10px] min-w-[200px]">Digital Count</td>
                  <td className="p-[10px] min-w-[200px]">Non Digital Count</td>
                  <td className="p-[10px] min-w-[200px]">Referral Count</td>
                </tr>
              </thead>
              <tbody>
                {isLoading ? (
                  <tr className="text-center w-full">
                    <td className="text-center border" colSpan={13}>
                      Loading...
                    </td>
                  </tr>
                ) : data.branches.length === 0 ? (
                  <tr>
                    <td className="text-center border" colSpan={13}>
                      No Data
                    </td>
                  </tr>
                ) : (
                  data?.branches?.map((item, index) => (
                    <tr key={index} className="border">
                      <td className="p-[10px]">{item.branch_name}</td>
                      <td className="p-[10px]">{item.total_count}</td>
                      <td className="p-[10px]">{item.digital_count}</td>
                      <td className="p-[10px]">{item.non_digital_count}</td>
                      <td className="p-[10px]">{item.referral_count}</td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>
      ) : status_id == 4 ? (
        <div>
          <h2>Procedure</h2>
          <div className="flex gap-[10px] justify-end">
            <div className="h-[40px] w-[200px] max-[520px]:mt-[5px] max-[520px]:w-full    ">
              {/* <Searchbar value={body.search} handleChange={searchHandler} /> */}
            </div>
            <div className="!border !border-slate-800 !border-opacity-20 rounded-md mt-[20px] md:mt-[0px]">
              <DatePicker1 handleValueChange={handleDateFilter} value={value} />
            </div>

            <div className="max-[520px]:mt-[5px] max-[575px]:w-full max-[520px]:mb-[50px] max-[575px]:mt-4  ">
              {localStorage.getItem("branch") == 0 ? (
                <select
                  placeholder="Branch"
                  className="sm:mb-[5px] h-[40px] w-[200px] capitalize bg-white border border-gray-300 rounded-lg p-[11px] text-[15px] outline-none"
                  name="branch"
                  value={body.branch}
                  onChange={(e) => {
                    const branchValue = e.target.value;
                  
                    setBody((prevState) => ({
                      ...prevState,
                      branch: branchValue,
                    }));
                  
                    setSorceId(branchValue);
                  }}
                  
                >
                  <option value={"0"}>--select branch--</option>
                  {branchDropdown.map((option) => (
                    <option
                      key={option.id}
                      value={option.id}
                      className="capitalize"
                    >
                      {option.branch_name}
                    </option>
                  ))}
                </select>
              ) : (
                ""
              )}
            </div>
          </div>

          <div className="w-full my-[10px] mb-[50px] overflow-x-scroll rounded-lg ">
          
            <table className="w-full">
              <thead className="border bg-secondary text-white">
                <tr>
                  <td className="p-[10px] min-w-[200px]">Branch Name</td>
                  <td className="p-[10px] min-w-[200px]">Total Count</td>
                  <td className="p-[10px] min-w-[200px]">IUI</td>
                  <td className="p-[10px] min-w-[200px]">IUI - D</td>
                  <td className="p-[10px] min-w-[200px]">LAPAROSCOPY</td>
                  <td className="p-[10px] min-w-[200px]">MYOMACTOMY</td>
                  <td className="p-[10px] min-w-[200px]">HYSTEROSCOPY</td>
                  <td className="p-[10px] min-w-[200px]">RECANALISATION</td>
                  <td className="p-[10px] min-w-[200px]">DNC</td>
                  <td className="p-[10px] min-w-[200px]">ICSI</td>
                  <td className="p-[10px] min-w-[200px]">OD ICSI</td>
                  <td className="p-[10px] min-w-[200px]">FET</td>
                  <td className="p-[10px] min-w-[200px]">PRP</td>
                  <td className="p-[10px] min-w-[200px]">ABCD</td>
                  <td className="p-[10px] min-w-[200px]">TESA/PESA</td>
                  <td className="p-[10px] min-w-[200px]">LAH</td>
                  <td className="p-[10px] min-w-[200px]">IMSI</td>
                  <td className="p-[10px] min-w-[200px]">PGD</td>
                  <td className="p-[10px] min-w-[200px]">NORMAL DELIVERY</td>
                  <td className="p-[10px] min-w-[200px]">LSCS</td>
                  <td className="p-[10px] min-w-[200px]">SSG</td>
                  <td className="p-[10px] min-w-[200px]">OBSERVATION</td>
                  <td className="p-[10px] min-w-[200px]">NICU</td>
                  <td className="p-[10px] min-w-[200px]">ERA</td>
                  <td className="p-[10px] min-w-[200px]">CYST ASPIRATION</td>
                  <td className="p-[10px] min-w-[200px]">PRP ENDOMETRIUM</td>
                  <td className="p-[10px] min-w-[200px]">TRIAL ET</td>
                  <td className="p-[10px] min-w-[200px]">Others</td>
                  <td className="p-[10px] min-w-[200px]">ENCIRCLAGE</td>
                  <td className="p-[10px] min-w-[200px]">D.Lap</td>
                  <td className="p-[10px] min-w-[200px]">ED</td>
                  <td className="p-[10px] min-w-[200px]">PICSI</td>
                  <td className="p-[10px] min-w-[200px]">Lap</td>
                  <td className="p-[10px] min-w-[200px]">Cho.Cyst</td>
                </tr>
              </thead>
              <tbody>
                {isLoading ? (
                  <tr className="text-center w-full">
                    <td className="text-center border" colSpan={13}>
                      Loading...
                    </td>
                  </tr>
                ) : data.branches.length === 0 ? (
                  <tr>
                    <td className="text-center border" colSpan={13}>
                      No Data
                    </td>
                  </tr>
                ) : (
                  data?.branches?.map((item, index) => (
                    <tr key={index} className="border">
                      <td
                        className="p-[10px] cursor-pointer"
                        onClick={(e) =>setSorceId(item.branch_id)}
                      > 
                        {item.branch_name}
                      </td>
                      <td className="p-[10px]">{item.total_count}</td>
                      {item?.procedures.map((items, index) => (
                        <>
                          <td className="p-[10px]">{items.procedure_count}</td>
                        </>
                      ))}
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>

          <div className="grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-3 my-[20px] gap-[50px]">
            
            <div className="max-[450px]:!overflow-x-scroll">
              <p  className="text-[18px] first-line:uppercase text-blue-600 font-bold"> Source Type Deatils </p>
            <h1 className="text-[18px] first-line:uppercase text-blue-600 font-bold mt-[30px]">{Digitalmarketing?.branch_name =="" ?"Super Admin":Digitalmarketing?.branch_name} :</h1>
              <div className="bg-[aliceblue] rounded-[25px] shadow p-[15px] sm:p-[30px] mt-[30px]">
                <p className="text-blue-600 text-base font-medium text-center">
                  Digital Marketing Counts
                </p>
                <div className="mt-[50px]">
                  <table className="w-full min-w-max table-auto text-left">
                    <tbody>
                      {Digitalmarketing?.list?.map((item) => {
                        return <>
 
                          {item.source_displaytypes_id == 1 ?
 
 
                            <tr className='!bg-[#fff]'>
                              <td className="py-4 px-2">
                                <div style={{ backgroundColor: item.source_colour }} className='w-[22px] h-[9px] rounded-sm'>
 
                                </div>
                              </td>
                              <td className="py-4 px-2 text-black text-sm font-medium">
                                {item.source_name}
                              </td>
                              <td className="py-4 px-2 text-black text-sm font-medium">
                                <div
                              //  onClick={() => {localStorage.setItem('entry_type', 1 ) ; localStorage.setItem('source_id', item.source_id);localStorage.setItem('source_displaytypes_id', 1);navigate("/" + item.link + "/" + fromDate  + '/' + toDate)}}
                                className='flex justify-center cursor-pointer hover:text-white hover:bg-[#a2eef4] hover:rounded-[20px]'>
                                {item.source_count}
                                </div>
                              </td>
                            </tr>
 
                            : ""}
                        </>
                      })}

                      <tr 
                      // onClick={() => {localStorage.setItem('entry_type', 1 ) ;localStorage.setItem('source_displaytypes_id', 1);navigate("/" + sourcelink + "/" + fromDate  + '/' + toDate)}}
                       className='!bg-blue-600 rounded-[10px] cursor-pointer'>
                        <td></td>
                        <td className='py-2 px-2 text-white text-lg font-semibold leading-7'>Total</td>
                        <td className='py-2 px-2 text-white text-lg font-semibold leading-7'>{Digitalmarketing?.digital}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="max-[450px]:!overflow-x-scroll">
              <div className="bg-[aliceblue] rounded-[25px] shadow p-[15px] sm:p-[30px] mt-[35px]">
                <p className="text-cyan-600 text-base font-medium text-center">
                  
                  Marketing Counts
                </p>
                <div className="mt-[50px]">
                  <table className="w-full min-w-max table-auto text-left">
                    <tbody>
                      {Digitalmarketing?.list?.map((item) => (
                        <>
                          {item.source_displaytypes_id == 2 ?
                            <tr className='!bg-[#fff]'>
                              <td className="py-4 px-2">
                                <div style={{ backgroundColor: item.source_colour }} className='w-[22px] h-[9px] rounded-sm'>
                                </div>
                              </td>
                              <td className="py-4 px-2 text-black text-sm font-medium">
                                {item.source_name}
                              </td>
                              <td className="py-4 px-2 text-black text-sm font-medium">
                              <div
                              //  onClick={() => {localStorage.setItem('entry_type', 1 ) ; localStorage.setItem('source_id', item.source_id);localStorage.setItem('source_displaytypes_id', 2);navigate("/" + item.link + "/" + fromDate  + '/' + toDate)}}
                                className='flex justify-center cursor-pointer hover:text-white hover:bg-[#a2eef4] hover:rounded-[20px]'>
                                {item.source_count}
                                </div>
                              </td>
                            </tr>
                            : ""}
                        </>
                      ))}
                      <tr 
                      // onClick={() => {localStorage.setItem('entry_type', 1 ) ;localStorage.setItem('source_displaytypes_id', 2);navigate("/" + sourcelink + "/" + fromDate  + '/' + toDate)}}
                       className='!bg-[#009EAC] rounded-[10px] cursor-pointer'>
                        <td></td>
                        <td className='py-2 px-2 text-white text-lg font-semibold leading-7'>Total</td>
                        <td className='py-2 px-2 text-white text-lg font-semibold leading-7'>{Digitalmarketing?.nondigital}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="max-[450px]:!overflow-x-scroll">
              <div className="bg-[aliceblue] rounded-[25px] shadow p-[15px] sm:p-[30px] mt-[35px]">
                <p className="text-cyan-800 text-base font-medium text-center">
                  Referral Counts
                </p>
                <div className="mt-[50px]">
                  <table className="w-full min-w-max table-auto text-left">
                    <tbody>
                      {Digitalmarketing?.list?.map((item) => (
                        <>
                          {item.source_displaytypes_id == 3 ?
                            <tr className='!bg-[#fff]'>
                              <td className="py-4 px-2">
                                <div style={{ backgroundColor: item.source_colour }} className='w-[22px] h-[9px] rounded-sm'>
                                </div>
                              </td>
                              <td className="py-4 px-2 text-black text-sm font-medium">
                                {item.source_name}
                              </td>
                              <td className="py-4 px-2 text-black text-sm font-medium">
                              <div
                              //  onClick={() => { localStorage.setItem('entry_type', 1 ) ;localStorage.setItem('source_id', item.source_id);localStorage.setItem('source_displaytypes_id', 3);navigate("/" + item.link + "/" + fromDate  + '/' + toDate)}}
                                className='flex justify-center cursor-pointer hover:text-white hover:bg-[#a2eef4]   hover:rounded-[20px]'>
                                {item.source_count}
                                </div>
                              </td>
                            </tr>
                            : ""}
                        </>
                      ))}
                      <tr 
                      // onClick={() => {localStorage.setItem('entry_type', 1 ) ;localStorage.setItem('source_displaytypes_id', 3);navigate("/" + sourcelink + "/" + fromDate  + '/' + toDate)}}
                       className='!bg-[#275582] rounded-[10px] cursor-pointer'>
                        <td></td>
                        <td className='py-2 px-2 text-white text-lg font-semibold leading-7'>Total</td>
                        <td className='py-2 px-2 text-white text-lg font-semibold leading-7'>{Digitalmarketing?.refdigital}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : status_id == 5 ? (
        <div>
           <h2>Admission</h2>
          <div className="flex gap-[10px] justify-end">
            <div className="h-[40px] w-[200px] max-[520px]:mt-[5px] max-[520px]:w-full    ">
              {/* <Searchbar value={body.search} handleChange={searchHandler} /> */}
            </div>
            <div className="!border !border-slate-800 !border-opacity-20 rounded-md mt-[20px] md:mt-[0px]">
              <DatePicker1 handleValueChange={handleDateFilter} value={value} />
            </div>

            <div className="max-[520px]:mt-[5px] max-[575px]:w-full max-[520px]:mb-[50px] max-[575px]:mt-4  ">
              {localStorage.getItem("branch") == 0 ? (
                <select
                  placeholder="Branch"
                  className="sm:mb-[5px] h-[40px] w-[200px] capitalize bg-white border border-gray-300 rounded-lg p-[11px] text-[15px] outline-none"
                  name="branch"
                  value={body.branch}
                  onChange={(e) => {
                    setBody((prevState) => ({
                      ...prevState,
                      branch: e.target.value,
                    }));
                  }}
                >
                  <option value={""}>--select branch--</option>
                  {branchDropdown.map((option) => (
                    <option
                      key={option.id}
                      value={option.id}
                      className="capitalize"
                    >
                      {option.branch_name}
                    </option>
                  ))}
                </select>
              ) : (
                ""
              )}
            </div>
          </div>

          <div className="w-full my-[10px] mb-[50px] overflow-x-scroll rounded-lg ">
            <table className="w-full">
              <thead className="border bg-secondary text-white">
                <tr>
                  <td className="p-[10px] min-w-[200px]">Branch Name</td>
                  <td className="p-[10px] min-w-[200px]">Total Count</td>
                  <td className="p-[10px] min-w-[200px]">Digital Count</td>
                  <td className="p-[10px] min-w-[200px]">Non Digital Count</td>
                  <td className="p-[10px] min-w-[200px]">Referral Count</td>
                </tr>
              </thead>
              <tbody>
                {isLoading ? (
                  <tr className="text-center w-full">
                    <td className="text-center border" colSpan={13}>
                      Loading...
                    </td>
                  </tr>
                ) : data.branches.length === 0 ? (
                  <tr>
                    <td className="text-center border" colSpan={13}>
                      No Data
                    </td>
                  </tr>
                ) : (
                  data?.branches?.map((item, index) => (
                    <tr key={index} className="border">
                      <td className="p-[10px]">{item.branch_name}</td>
                      <td className="p-[10px]">{item.total_count}</td>
                      <td className="p-[10px]">{item.digital_count}</td>
                      <td className="p-[10px]">{item.non_digital_count}</td>
                      <td className="p-[10px]">{item.referral_count}</td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>
      ) : status_id == 6 ? (
        <div>
           <h2>Discharge</h2>
          <div className="flex gap-[10px] justify-end">
            <div className="h-[40px] w-[200px] max-[520px]:mt-[5px] max-[520px]:w-full    ">
              {/* <Searchbar value={body.search} handleChange={searchHandler} /> */}
            </div>
            <div className="!border !border-slate-800 !border-opacity-20 rounded-md mt-[20px] md:mt-[0px]">
              <DatePicker1 handleValueChange={handleDateFilter} value={value} />
            </div>

            <div className="max-[520px]:mt-[5px] max-[575px]:w-full max-[520px]:mb-[50px] max-[575px]:mt-4  ">
              {localStorage.getItem("branch") == 0 ? (
                <select
                  placeholder="Branch"
                  className="sm:mb-[5px] h-[40px] w-[200px] capitalize bg-white border border-gray-300 rounded-lg p-[11px] text-[15px] outline-none"
                  name="branch"
                  value={body.branch}
                  onChange={(e) => {
                    setBody((prevState) => ({
                      ...prevState,
                      branch: e.target.value,
                    }));
                  }}
                >
                  <option value={""}>--select branch--</option>
                  {branchDropdown.map((option) => (
                    <option
                      key={option.id}
                      value={option.id}
                      className="capitalize"
                    >
                      {option.branch_name}
                    </option>
                  ))}
                </select>
              ) : (
                ""
              )}
            </div>
          </div>

          <div className="w-full my-[10px] mb-[50px] overflow-x-scroll rounded-lg ">
            <table className="w-full">
              <thead className="border bg-secondary text-white">
                <tr>
                  <td className="p-[10px] min-w-[200px]">Branch Name</td>
                  <td className="p-[10px] min-w-[200px]">Total Count</td>
                  <td className="p-[10px] min-w-[200px]">Digital Count</td>
                  <td className="p-[10px] min-w-[200px]">Non Digital Count</td>
                  <td className="p-[10px] min-w-[200px]">Referral Count</td>
                </tr>
              </thead>
              <tbody>
                {isLoading ? (
                  <tr className="text-center w-full">
                    <td className="text-center border" colSpan={13}>
                      Loading...
                    </td>
                  </tr>
                ) : data.branches.length === 0 ? (
                  <tr>
                    <td className="text-center border" colSpan={13}>
                      No Data
                    </td>
                  </tr>
                ) : (
                  data?.branches?.map((item, index) => (
                    <tr key={index} className="border">
                      <td className="p-[10px]">{item.branch_name}</td>
                      <td className="p-[10px]">{item.total_count}</td>
                      <td className="p-[10px]">{item.digital_count}</td>
                      <td className="p-[10px]">{item.non_digital_count}</td>
                      <td className="p-[10px]">{item.referral_count}</td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default Branchandprocedure;
