import React, { useEffect, useState } from "react";
import DatePicker1 from "../../components/DatePicker1";
import Searchbar from "../../components/SearchBar";
import { getAxios, postAxiosWithToken } from "../../service/apiservice";
// import CustomePagination from '../../../components/CustomPagination';
import CustomPagination from "../../components/CustomPagination";
import { notificationStatusDropdownEndPoint, notificationStatusListDataEndPoint, notificationTableListDataEndPoint, sorceTypeListEndPoint } from "../../service/api";
import { convertToDate } from "../../utilities/datetime";
import { convertToTime } from "../../utilities/time";
import Model from "../../components/Model";
import Popup from "../../components/Popup";
import ViewForm from "./viewForm";
import { ExportToExcel } from "../../components/ExportToExcel";
function Notification() {
  const [totalPages, setTotalPages] = useState(1);
  const [notificationTableList, setNotificationTableList] = useState([]);
  const [isStatusOpen, setIsStatusOpen] = useState(false);

  const [currentstatus, setCurrentstatus] = useState([]);
  // const [isviewOpen, setIsviewOpen] = useState(false);
  const [value, setValue] = useState({
    startDate: null,
    endDate: null,
  });

  const[data,setData]=useState([]);

  const [isLoading, setIsLoading] = useState(true);
  // const [totalitems, setTotalitems] = useState({});
  const [sourceTypeDropdown, setSourceTypeDropdown] = useState([]);
  const [body, setBody] = useState({
    from_date: "",
    to_date: "",
    branch: Number(localStorage.getItem("branch")),
    search: "",
    notification_status:"0",
    page: 1,
    items_per_page: 10,
    
  });

  function closeStatusModal() {
    setIsStatusOpen(false);
  }

  useEffect(() => {
    getCancerLeadsListData();
  }, [body]);




  useEffect(()=>{
    sourceTypeDropdownList();
  },[]);





  


  const getCancerLeadsListData = async () => {
    var response = await postAxiosWithToken({
      url: notificationTableListDataEndPoint,
      body: body,
    });

    if (response !== null) {
      setNotificationTableList(response.data.list);
      setTotalPages(response.data.total_pages);
      setIsLoading(false);
    }
  };



  useEffect(() => {
    let tempData = [];
    for (var i of notificationTableList) {
        // tempData.push(i)
        tempData.push({
          patient_name: i.patient_name,
          status_name: i.status_name,
          new_lead_type: i.new_lead_type,
          new_source_type: i.new_source_type,
          existing_lead_type: i.existing_lead_type,
          existing_source_type: i.existing_source_type,
          created_by_name: i.created_by_name,
          branch_name: i.branch_name,
          create_date: i.create_date,
        })
    }
    setData(tempData)

}, [notificationTableList])



  const sourceTypeDropdownList = async () => {
    var response = await getAxios({
      url: notificationStatusDropdownEndPoint,
    });

    if (response !== null) {
      setSourceTypeDropdown(response.data.list);
    }
  };



  

  // Filter
  const handleDateFilter = async (e) => {
    if (e.startDate === null && e.endDate === null) {
      setBody((prevState) => ({
        ...prevState,
        from_date: "",
        to_date: "",
        page: 1,
      }));
    } else {
      setBody((prevState) => ({
        ...prevState,
        from_date: e.startDate,
        to_date: e.endDate,
        page: 1,
      }));
    }

    setValue(e);
  };

  const searchHandler = (e) => {
    setBody((prevState) => ({
      ...prevState,
      search: e.target.value,
      page: 1,
    }));
  };

  const pageHandler = (value) => {
    setBody((prevState) => ({
      ...prevState,
      page: value,
    }));
  };

  const entriesHandler = (value) => {
    setBody((prevState) => ({
      ...prevState,
      items_per_page: value,
      page: 1,
    }));
  };


  

  return (
    <div>
      <div className="flex gap-[20px] items-center h-[40px] justify-between mb-[24px] mt-[24px] max-[425px]:block max-[425px]:mb-[50px] ">
        <p className=" sm:mb-[5px] bg-white border-none p-[5px]  text-[#1D1D1F] text-[24px] font-[500px] outline-none">
          Notification
        </p>

        <div className="flex gap-[10px] max-[520px]:block max-[520px]:mt-5">
        <ExportToExcel apiData={data} fileName="Notification" />
          <div className=" rounded-md mt-[20px] md:mt-[0px]">
             <select
            placeholder="Source"
            className="sm:mb-[5px] h-[40px] w-full capitalize bg-white border border-gray-300 rounded-lg  p-[8px] text-[15px] outline-none"
            name="approve_source_type"
            onChange={(e) => {
              setBody((prevState) => ({
                ...prevState,
                notification_status: e.target.value,
                page: 1,
              }));

            }}
          
          >
            <option value={"0"}>--Select Source Type --</option>
            {sourceTypeDropdown .map((option) => (
              <option key={option.id} value={option.id} className="capitalize">
                {option.status_name}
              </option>
            ))}
          </select>
          </div>

          
          <div className="!border !border-slate-800 !border-opacity-20 rounded-md mt-[20px] md:mt-[0px]">
            <DatePicker1 handleValueChange={handleDateFilter} value={value} />
          </div>

          <div className="h-[40px] w-[200px] max-[520px]:mt-[5px] max-[520px]:w-full">
            <Searchbar value={body.search} handleChange={searchHandler} />
          </div>
        </div>
      </div>

      <div className="w-full my-[10px] mb-[50px] overflow-x-scroll rounded-lg ">
        <table className="w-full">
          <thead className="border bg-secondary text-white">
            <tr>
              <td className="p-[10px]">S.No</td>
              <td className="p-[10px] min-w-[200px]">Patient Name</td>
              <td className="p-[10px] min-w-[200px]">Status</td>
              <td className="p-[10px] min-w-[150px]">Current Mobile </td>
              <td className="p-[10px] min-w-[150px]">Existing Mobile </td>
              <td className="p-[10px] min-w-[200px]">Branch</td>
              <td className="p-[10px] min-w-[200px]">New Lead Type</td>
              <td className="p-[10px] min-w-[200px]">New Source Type</td>
              <td className="p-[10px] min-w-[200px]">Existing Lead Type</td>
              <td className="p-[10px] min-w-[200px]">Existing Source Type</td>
              <td className="p-[10px] min-w-[200px]">Types of Way</td>
              <td className="p-[10px] min-w-[200px]">Lead Date</td>
              <td className="p-[10px] min-w-[200px]">Created by</td>

              <td className="p-[10px] min-w-[200px]">Create Date</td>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr className="text-center w-full">
                <td className="text-center border" colSpan={6}>
                  Loading...
                </td>
              </tr>
            ) : notificationTableList.length === 0 ? (
              <tr>
                <td className="text-center border" colSpan={6}>
                  No Data
                </td>
              </tr>
            ) : (
              notificationTableList.map((item, index) => (
                <tr key={item.id} className="border">
                  <td className="p-[10px]">{item.sno}</td>

                  <td className="p-[10px]">{item.patient_name}</td>
                  <td className="p-[10px] capitalize">
                    <div className="relative">
                      <div
                        className="text-white px-[5px] py-[10px] rounded-[10px] w-[200px]"
                        style={{ backgroundColor: item.status_color }}
                      >
                        
                          <div
                            onClick={() => {
                              setCurrentstatus(item);
                              setIsStatusOpen(true);
                            }}
                            className="flex gap-[10px] justify-center items-center cursor-pointer"
                          >
                            {item.status_name}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="1em"
                              height="1em"
                              viewBox="0 0 24 24"
                            >
                              <g
                                fill="none"
                                stroke="white"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              >
                                <path d="M8 13V4.5a1.5 1.5 0 0 1 3 0V12m0-.5v-2a1.5 1.5 0 0 1 3 0V12m0-1.5a1.5 1.5 0 0 1 3 0V12" />
                                <path d="M17 11.5a1.5 1.5 0 0 1 3 0V16a6 6 0 0 1-6 6h-2h.208a6 6 0 0 1-5.012-2.7L7 19c-.312-.479-1.407-2.388-3.286-5.728a1.5 1.5 0 0 1 .536-2.022a1.867 1.867 0 0 1 2.28.28L8 13M5 3L4 2m0 5H3m11-4l1-1m0 4h1" />
                              </g>
                            </svg>
                          </div>
                        
                      </div>
                    </div>
                  </td>

                  <td className="p-[10px]">{item.mobile}</td>
                  <td className="p-[10px]">{item.previous_mobile}</td>
                  <td className="p-[10px]">{item.branch_name}</td>
                  <td className="p-[10px]">{item.new_lead_type}</td>
                  <td className="p-[10px]">{item.new_source_type}</td>
                  <td className="p-[10px]">{item.existing_lead_type}</td>
                  <td className="p-[10px]">{item.existing_source_type}</td>
                  <td className="p-[10px]">{item.typesofway}</td>
                  <td className="p-[10px]">{item.lead_date}</td>
                  <td className="p-[10px]">{item.created_by_name}</td>
                  <td className="p-[10px]">
                    <p className="text-[#262831] text-[14px] fony-[500]">
                      {convertToDate(item.created_at ? item.created_at : "--")}
                    </p>
                    <p className="text-[#BCBDBD] text-[12px] fony-[500]">
                      {convertToTime(item.created_at ? item.created_at : "--")}
                    </p>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>

      <div className="mb-[50px]">
        <CustomPagination
          totalPages={totalPages}
          currentPage={body.page}
          setCurrentPage={pageHandler}
          entries={body.items_per_page}
          entriesHandler={entriesHandler}
          // totalitmes={totalitems.total_items}
        />
      </div>

      <Popup
        title={"Status Change"}
        setIsOpen={setIsStatusOpen}
        isOpen={isStatusOpen}
        closeModal={closeStatusModal}
      >
        <ViewForm getData={getCancerLeadsListData} onClose={closeStatusModal} currentstatus={currentstatus} />
        {/* <LeadDropDown getData={getAllLeadsListData} onClose={closeStatusModal} currentstatus={currentstatus} /> */}
      </Popup>
    </div>
  );
}

export default Notification;
