import LogoImage from "../../assets/images/Shiplogologin.svg";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import toast, { Toaster } from 'react-hot-toast';
import { useSelector } from "react-redux";
import { useState } from "react";
import { postAxios } from "../../service/apiservice";
import { loginEndPoint } from "../../service/api";
import { useGeolocated } from 'react-geolocated';
import { useNavigate } from "react-router-dom";

export default function Login() {

    const navigate = useNavigate();

    const isDark = useSelector((state) => state.themeConfig.isDark)
    const [encryptPasswordValue, setEncryptPasswordValue] = useState(true);

    const { coords, isGeolocationAvailable, isGeolocationEnabled } =
    useGeolocated({
      positionOptions: {
        enableHighAccuracy: false,
      },
      userDecisionTimeout: 5000,
    });

    const schema = yup
        .object({
            username: yup.string().required(),
            password: yup.string().required(),
        })
        .required()

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    })

    const onSubmit = (data) => {
        loginUser(data)
    }

    // const loginUser = async (body) => {
    //             let response = await postAxios({
    //                 url: loginEndPoint,
    //                 body: body
    //             })
        
    //             if (response.status === 200) {
    //                 localStorage.setItem('login', JSON.stringify(response.data))
    //                 localStorage.setItem('branch', response.data.branch)
    //                 localStorage.setItem('user_role_id', response.data.user_role_id)
    //                 localStorage.setItem('authenticated', JSON.stringify(true))
    //                 navigate("/dashboard")
    //                 window.location.reload()
    //             } else {
    //                 toast.error(response.message)
    //             }
        
        
    // }

    const loginUser = async (body) => {
        let response = await postAxios({
            url: loginEndPoint,
            body: body
        })
        
 
        if (response?.status === 200) {
                     localStorage.setItem('login', JSON.stringify(response.data))
                     localStorage.setItem('calllog',response.data.calllog)
                    localStorage.setItem('branch', response.data.branch)
                    localStorage.setItem('branchdashboard', response.data.branch)
                    localStorage.setItem('user_role_id', response.data.user_role_id)
                    localStorage.setItem('authenticated', JSON.stringify(true))
                    navigate("/dashboard")
                    window.location.reload()
        } else {
            toast.error(response?.message)
        }
    }



    return (
        <>
            <div>

                <div className="md:flex w-full">
                    <div className="relative w-full md:w-[50%] h-[100vh]">
                        <div className="overflow-hidden w-full h-[100vh] bg-login-pattern bg-no-repeat bg-cover max-[500px]:bg-center">
                            {/* <img src={BgLoginImage} alt="no-image" /> */}
                        </div>

                        <div className="absolute top-0 p-[50px] h-[100vh] flex flex-col justify-center ">
                            <h1 className="font-[500] text-[29px] md:text-[34px] text-white max-[480px]:text-[25px] ">Welcome Always!</h1>
                            <div className="font-[400] text-[60px] md:text-[30px]  text-white break-words max-[655px]:text-[35px] max-[480px]:text-[20px] ">Sudha Hospital Integrated Platform</div>
                            <p className="text-[#D9D9D9] text-[14px] max-[480px]:text-[10px]">Welcome to Ship CRM. knowledge base platform. Great experience, from service, to tailored design, to ease of use</p>
                        </div>

                        <div className="absolute bottom-0 w-full p-[20px] md:p-[50px] flex items-center gap-[30px] max-[480px]:justify-center">
                            <p className="font-[400] md:text-[10px] text-white break-words  max-[480px]:text-[8px]">Privacy Policy</p>
                            <p className="font-[400] md:text-[10px] text-white break-words  max-[480px]:text-[8px]">Terms & Conditions</p>
                            <p className="font-[400] md:text-[10px] text-white break-words  max-[480px]:text-[8px]">About Us</p>
                        </div>
                    </div>
                    <div className="w-full md:w-[50%] mt-[50px] flex flex-col justify-center items-center max-[767px]:h-[100vh]">
                        <div className="lg:w-[110px] w-[110px] mb-[10px]">
                            <img src={LogoImage} alt="no-logo" />
                        </div>
                        <div className={`${isDark ? "bg-dark-bg" : "bg-white"}  p-[40px] w-fit h-fit`}>
                            <div className="mb-[40px] text-center">
                                <p className="font-[400] text-[25px] mb-[10px]">Sign In</p>
                            </div>

                            <form className="xl:w-[500px] lg:w-[350px]" onSubmit={handleSubmit(onSubmit)}>
                                <div className="my-[15px]">
                                    <input
                                        type="text"
                                        placeholder="Email or Phone"
                                        className={`border-2 border-[#efefef] bg-[#f3f3ff] w-full px-[12px] py-[10px] rounded-[5px] outline-none`}
                                        name="username"
                                        {...register('username')}
                                    />
                                    <p className="text-red-500 font-[400] text-[13px]">{errors.username?.message}</p>
                                </div>
                                <div className="my-[15px]">
                                    <div className="flex items-center rounded-[5px] px-[12px] py-[10px] border-2 border-[#efefef] bg-[#f3f3ff] w-full">
                                        <input
                                            type={!encryptPasswordValue ? "text" : "password"}
                                            placeholder="Password"
                                            className={`bg-[#f3f3ff] w-full   outline-none`}
                                            name="password"
                                            {...register('password')}
                                        />
                                        <div onClick={() => setEncryptPasswordValue(true)} className={`${!encryptPasswordValue ? 'block' : 'hidden'}`}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24"><path fill="currentColor" d="M12 9a3 3 0 0 0-3 3a3 3 0 0 0 3 3a3 3 0 0 0 3-3a3 3 0 0 0-3-3m0 8a5 5 0 0 1-5-5a5 5 0 0 1 5-5a5 5 0 0 1 5 5a5 5 0 0 1-5 5m0-12.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5Z" /></svg>
                                        </div>
                                        <div onClick={() => setEncryptPasswordValue(false)} className={`${encryptPasswordValue ? 'block' : 'hidden'}`}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><g fill="currentColor"><path d="m10.79 12.912l-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z" /><path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708l-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6l-12-12l.708-.708l12 12l-.708.708z" /></g></svg>
                                        </div>
                                    </div>
                                    <p className="text-red-500 font-[400] text-[13px]">{errors.password?.message}</p>
                                </div>
                                <button
                                    type="submit"
                                    className={`${isDark ? "bg-secondary" : "bg-primary"} mt-[25px] capitalize text-white w-full rounded-lg py-[10px] font-[500] text-[17px] outline-none border-none`}
                                >Sign In</button>
                                {/* <button
                                    className={`${isDark ? "bg-secondary" : "border border-primary"} mt-[25px] capitalize text-primary w-full rounded-lg py-[10px] font-[500] text-[17px] outline-none`}
                                >Login With Pin</button> */}
                            </form>
                        </div>
                    </div>
                </div>

                <Toaster
                    position="top-center"
                    reverseOrder={false}
                />

            </div>
        </>
    );
}