import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { getAxios, postAxios, postAxiosWithToken } from '../../../../service/apiservice';
import { branchListEndPoint, incommingEndPoint, LeadTypeDataEndPoint, patientMobilenumberEndpoint, sorceTypeListEndPoint, sorceTypeotherListEndPoint } from '../../../../service/api';
import toast from 'react-hot-toast';
import { phoneRegExp } from '../../../../constant';
import { Navigate, useNavigate } from 'react-router-dom';

const Addincomingform = ({ onClose, getAllLeadsListData }) => {
  const [formMode, setFormMode] = useState('create');
  const [branchDropdown, setBranchDropdown] = useState([]);
  const [sorceDropdown, setSorceDropdown] = useState([]);
  const [selectedOptionLabel, setSelectedOptionLabel] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [alterNumber, setAlterNumber] = useState("");
  const [usedNumber, setUsedNumber] = useState(null);
  const [hasPatientDetails, setHasPatientDetails] = useState(false);
  const [currentPatient, setCurrentPatient] = useState({});
  const [errors, setErrors] = useState(null);
  const [sourceDisplaytype, setSourceDisplaytype] = useState(2);
  const [response, setResponse] = useState({});
  const [leadtypeDropdown, setLeadTypeDropdown] = useState([]);
  const [dataCame,setDataCame ] = useState(false);



  const mobileNumbers = {
    mobile: mobileNumber,
    alternate_no: alterNumber
  };



  useEffect(() => {
    getBranchDropdownData();
    getSorceTypeDropdownData();
    getLeadDropdownData();
  }, [])


  // useEffect(() => {
  //   handleSearch()
  // }, [mobileNumber])

  const getLeadDropdownData = async () => {
    var response = await getAxios({
      url: LeadTypeDataEndPoint,
    });

    if (response !== null) {
      setLeadTypeDropdown(response.data.list);
    }
  };


  useEffect(() => {
    if (mobileNumber.length !== 10) {
     
      if (hasPatientDetails == true && usedNumber == 'alternate') {
        setDataCame(true);
      } else if (hasPatientDetails == true && usedNumber == 'phone') {
        setDataCame(false);
        setHasPatientDetails(false);
        // clearData1();
      } else {
        setDataCame(false);
        setHasPatientDetails(false);
      }
    } else {
      if (!hasPatientDetails) {
        handleSearch(mobileNumber, alterNumber)
      }
    }
  },[mobileNumber])

  useEffect(() => {
    if (alterNumber.length !== 10) {
      
      if (hasPatientDetails == true && usedNumber == 'phone') {
        setDataCame(true);
      } else if (hasPatientDetails == true && usedNumber == 'alternate') {
        setDataCame(false);
        setHasPatientDetails(false);
        // clearData1();
      } else {
        setDataCame(false);
        setHasPatientDetails(false);
      }
    } else {
      if (!hasPatientDetails) {
        handleSearch(mobileNumber, alterNumber)
      }
    }
  },[alterNumber])


  const navigate = useNavigate();


  const onValueUpdate = (e) => {
    const { name, value } = e.target;

    if (name == "source_type") {
      const selectedLabel = e.target.options[e.target.selectedIndex].label;
      setSelectedOptionLabel(selectedLabel);
    }
    if (name == "mobile") {
      setMobileNumber(e.target.value);
    }
    if (name == "additional_mobile") {
      setAlterNumber(e.target.value);
      // handleSearch();
    }

    setCurrentPatient((prevState) => ({
      ...prevState,
      [name]: value,

    }));

    setErrors((prevState) => ({
      ...prevState,
      [name]: value.length === 0 ? `${name} field is required` : "",
    }));
  };

  // const onSubmit = async (data) => {
  //   if (localStorage.getItem('incomming_form_mode') === 'create') {
  //     data['branch'] = document.getElementById('branch').value
  //     // createallIncommingLeads(data)
  //   } else {

  //     // updateStaff(data)
  //   }
  // }




  const isValidForm = (formDataObject) => {
    const err = {};
    Object.entries(formDataObject).forEach(([field, value]) => {
      if (value.length === 0) {
        err[field] = `${field} field is required`;
      } else {
        err[field] = "";
      }
    });

    // Validate mobile number field
    const mobileRegex = /^[0-9]{10}$/; // Assumes a 10-digit mobile number
    if (!mobileRegex.test(formDataObject.mobile)) {
      err['mobile'] = 'Invalid mobile number';
    } else {
      err['mobile'] = '';
    }

    // Additional validation for mobile number length
    if (formDataObject.mobile.length > 10) {
      err['mobile'] = 'Mobile number should be 10 digits';
    }


    err['appointment_date'] = '';
    err['additional_mobile'] = '';
    err['remarks'] = '';
    err['email'] = '';
    err['camp_name'] = '';
    err['marriage_since'] = '';

    setErrors((prevState) => ({
      ...prevState,
      ...err,
    }));

    const hasErrors = Object.values(err).some((error) => error.length > 0);

    return !hasErrors;
  };



  // const onSubmit = (e) => {
  //   e.preventDefault();

  //   const formFields = new FormData(e.target);
  //   const formDataObject = Object.fromEntries(formFields.entries());

  //   if (isValidForm(formDataObject)) {

  //     if (localStorage.getItem("incomming_form_mode") === "create") {
  //       currentPatient['branch'] = document.getElementById('branch').value
  //       createallIncommingLeads(currentPatient);

  //     }
  //   } else {
  //     console.log("Form has validation errors. Please correct them.");
  //   }
  // };

  // const createallIncommingLeads = async (body) => {
  //   console.log("body",body)
  //   var response = await postAxiosWithToken({
  //     url: incommingEndPoint,
  //     body: body
  //   })

  //   if (response != null) {
  //     toast.success(response.message)
  //     onClose();
  //     // getAllLeadsListData();
  //     navigate("/leads/confirmed")
  //   }
  // }


  const onSubmit = (e) => {
    e.preventDefault();
  
    const formFields = new FormData(e.target);
    const formDataObject = Object.fromEntries(formFields.entries());
  
    if (isValidForm(formDataObject)) {
      const formMode = localStorage.getItem("incomming_form_mode");
      console.log("Form mode:", formMode);
  
      if (formMode === "create") {
        const branchElement = document.getElementById('branch');
        if (!branchElement) {
          console.error("'branch' element not found in the DOM.");
          return;
        }
        currentPatient.branch = branchElement.value;
  
        createallIncommingLeads(currentPatient);
      }
    } else {
      console.log("Form has validation errors. Please correct them.");
    }
  };
  
  const createallIncommingLeads = async (body) => {
    try {
      console.log("Body sent to API:", body);
      const response = await postAxiosWithToken({
        url: incommingEndPoint,
        body: body,
      });
  
      if (response) {
        toast.success(response.message);
        onClose();
        navigate("/leads/confirmed");
      } else {
        throw new Error("Response is null or undefined.");
      }
    } catch (error) {
      console.error("API Error:", error);
      toast.error("Failed to create incoming lead. Please try again.");
    }
  };
  




  const handleSearch = async () => {
    let mobile_no = mobileNumbers?.mobile?.length || mobileNumbers?.mobile?.length ; // Get the length of the mobile number
   // Get the length of the mobile number
    // if(sourceDisplaytype == 2 )
    if (mobile_no === 10) {  // Checking if mobile_no exists and its length is 10
      var response = await postAxiosWithToken({
        url: patientMobilenumberEndpoint,
        body: mobileNumbers,
        showToast: false
      });

      // if (response != null) {
      //   toast.success(response.message);
      //   setResponse(response)
      //   var tempDetails = {
      //     name: response.data?.name,
      //     additional_mobile: response.data?.additional_mobile,
      //     mobile: response.data?.mobile,
      //     marriage_since: response.data?.marriage_since,
      //     email: response.data?.email,
      //     source_type: response.data?.source_type,
      //     remarks: response.data?.remarks,
      //     branch: response.data?.branch,
      //     location: response.data?.location,
      //     appointment_date: response.data?.appointment_date,
      //     camp_name: response.data?.camp_name,
      //   }
      //   setCurrentPatient(tempDetails);
      //   // setSourceDisplaytype(source_type__source_displaytypes_id);

      // }

      if (response != null) {
        toast.success(response.message);

        setHasPatientDetails(true);
        setUsedNumber(response.is_phone ? 'phone' : 'alternate')
        setDataCame(true);

            var tempDetails = {
                name: response.data?.name,
                additional_mobile: response.data?.additional_mobile,
                mobile: response.data?.mobile,
                marriage_since: response.data?.marriage_since,
                email: response.data?.email,
                source_type: response.data?.source_type,
                remarks: response.data?.remarks,
                branch: response.data?.branch,
                location: response.data?.location,
                appointment_date: response.data?.appointment_date,
                camp_name: response.data?.camp_name,
                spouse_name: response.data?.spouse_name,
                lead_type: response.data?.lead_type,
              }
        setCurrentPatient(tempDetails);
      } else{
        setHasPatientDetails(false);
        setDataCame(false);
        // clearData1();
      } 
    }
  };








  const getBranchDropdownData = async () => {
    var response = await getAxios({
      url: branchListEndPoint,
    });

    if (response !== null) {
      setBranchDropdown(response.data.list);
    }
  };
  const getSorceTypeDropdownData = async () => {
    var response = await getAxios({
      url: sorceTypeotherListEndPoint,
    });

    if (response !== null) {
      setSorceDropdown(response.data.list);
    }
  };


  return (
    <div>


      <form onSubmit={onSubmit}>
        <div className="w-full h-full pb-[30px] pt-[20px] px-[30px]">


          <div className="flex gap-[10px] mt-4">
            <div className="w-1/2">
              <p className="mb-[5px] text-[15px]">Name <span className="text-red-500">*</span></p>

              <input
                type="text"
                name="name"
                placeholder="Name"
                className="sm:mb-[5px] h-[45px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                value={currentPatient.name}
                onChange={onValueUpdate}
              />
              <p style={{ color: 'red' }}>{errors?.name}</p>
            </div>

            <div className="w-1/2">
              <p className="mb-[5px] text-[15px]">E-mail  </p>
              <input
                type="email"
                name="email"
                id="email"
                placeholder="E-mail"
                className="sm:mb-[5px] h-[45px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                value={currentPatient.email}
                onChange={onValueUpdate}
              />
              {/* <p style={{ color: 'red' }}>{errors?.email}</p> */}

            </div>
          </div>
          <div className="flex gap-[10px] mt-4">
            <div className="w-1/2">
              <p className="mb-[5px] text-[15px]">Father/Spouse Name  <span className="text-red-500">*</span></p>

              <input
                type="text"
                name="spouse_name"
                placeholder="Name"
                className="sm:mb-[5px] h-[45px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                value={currentPatient.spouse_name}
                onChange={onValueUpdate}
              />
              <p style={{ color: 'red' }}>{errors?.spouse_name}</p>
            </div>

            <div className="w-1/2">
              <p className="mb-[5px] text-[15px]">Married Since
                 {/* <span className="text-red-500">*</span> */}
              </p>
              <input
                type="date"
                name="marriage_since"
                id="marriage_since"
                placeholder=" Married Since"
                className="sm:mb-[5px] h-[45px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                value={currentPatient.marriage_since}
                onChange={onValueUpdate}
              />
              <p style={{ color: 'red' }}>{errors?.marriage_since}</p>
            </div>
          </div>

          <div className="flex gap-[10px] mt-4">
            <div className="w-1/2">
              <p className="mb-[5px] text-[15px]">Mobile Number  <span className="text-red-500">*</span></p>
              <input
                type="text"
                name="mobile"
                id="mobile"
                placeholder=" Mobile Number"
                className="sm:mb-[5px] h-[45px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                value={currentPatient.mobile}
                onChange={onValueUpdate}
              //   onChange={(e) => {
              //     setMobileNumber(e.target.value);
              //     handleSearch();
              //     onValueUpdate(e.target);
              // }}
              />
              <p style={{ color: 'red' }}>{errors?.mobile}</p>
            </div>


            <div className="w-1/2">
              <p className="mb-[5px] text-[15px]">Additional Mobile Number </p>
              <input
                type="text"
                name="additional_mobile"
                id="additional_mobile"
                placeholder="Additional Mobile Number"
                className="sm:mb-[5px] h-[45px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                value={currentPatient.additional_mobile}
                onChange={onValueUpdate}
              />
            </div>



          </div>

          <div className="flex gap-[10px] mt-4">


            {localStorage.getItem("branch") !== "0" ?
              <div className="w-1/2 ">
                <p className="mb-[5px] text-[15px]">Branch  <span className="text-red-500">*</span></p>
                <select
                  placeholder="Branch"
                  id="branch"
                  className="sm:mb-[5px] h-[45px] w-[200px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[15px] outline-none"
                  name="branch"
                  value={localStorage.getItem('branch')}
                  disabled
                >
                  <option value={""}>--select branch--</option>
                  {branchDropdown.map((option) => (
                    <option key={option.id} value={option.id} className="capitalize">
                      {option.branch_name}
                    </option>
                  ))}
                </select>
                <p style={{ color: 'red' }}>{errors?.branch}</p>
              </div>
              :



              <div className="w-1/2 ">
                <p className="mb-[5px] text-[15px]">Branch  <span className="text-red-500">*</span></p>
                <select
                  placeholder="Branch"
                  id="branch"
                  className="sm:mb-[5px] h-[45px] w-[200px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[15px] outline-none"
                  name="branch"
                  defaultValue={localStorage.getItem("branchid") || ""}
                >
                  <option value={""}>--select branch--</option>
                  {branchDropdown.map((option) => (
                    <option key={option.id} value={option.id} className="capitalize">
                      {option.branch_name}
                    </option>
                  ))}
                </select>  <p style={{ color: 'red' }}>{errors?.branch}</p>
              </div>
            }





            <div className="w-1/2">
              <p className="mb-[5px] text-[15px]">location  <span className="text-red-500">*</span></p>
              <input
                type="text"
                name="location"
                id="location"
                placeholder="Location"
                className="sm:mb-[5px] h-[45px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                value={currentPatient.location}
                onChange={onValueUpdate}
              />
              <p style={{ color: 'red' }}>{errors?.location}</p>
            </div>




          </div>

          <div className="flex gap-[10px] mt-4">
           
            <div className="w-1/2">
              <p className="text-black text-[15px] font-500 mt-1">Appointment Date </p>

              <div className="flex gap-[10px] items-center">
                <input
                  type="date"
                  name="appointment_date"
                  id='appointment_date'
                  className="sm:mb-[5px] h-[45px] w-full border border-gray-300 rounded-lg p-[10px] text-[17px] outline-none"
                  onChange={onValueUpdate}
                  value={currentPatient.appointment_date}
                />
              </div>
            </div>

            
            {response.status === 200 ?
              <div className="w-1/2">
                <p className="mb-[5px] text-[15px]">Source Type  <span className="text-red-500">*</span></p>
                <select
                  placeholder="Source Type"
                  id="source_type"
                  className="sm:mb-[5px] h-[45px] w-[200px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[15px] outline-none"
                  name="source_type"
                  // onClick={(e)=> setcampName(e.target.value)}
                  value={currentPatient.source_type}
                  onChange={onValueUpdate}
                  readonly
                >
                  <option value={""}>--select source--</option>
                  {sorceDropdown.map((option) => (
                    <option
                      key={option.id}
                      value={option.id}
                      className="capitalize"
                    >
                      {option.source_name}
                    </option>
                  ))}
                </select>
                <p style={{ color: 'red' }}>{errors?.source_type}</p>
              </div> : 
              <div className="w-1/2">
                <p className="mb-[5px] text-[15px]">Source Type  <span className="text-red-500">*</span></p>
                <select
                  placeholder="Source Type"
                  id="source_type"
                  className="sm:mb-[5px] h-[45px] w-[200px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[15px] outline-none"
                  name="source_type"
                  // onClick={(e)=> setcampName(e.target.value)}
                  value={currentPatient.source_type}
                  onChange={onValueUpdate}
                  disabled={dataCame}
                >
                  <option value={""}>--select source--</option>
                  {sorceDropdown.map((option) => (
                    <option
                      key={option.id}
                      value={option.id}
                      className="capitalize"
                    >
                      {option.source_name}
                    </option>
                  ))}
                </select>
                <p style={{ color: 'red' }}>{errors?.source_type}</p>
              </div>
            }

            {
              selectedOptionLabel.split(" ").join("-").toLowerCase() === "camp-other" && "camp-digital" ?

                <div className='w-1/2 '>
                  <p className="mb-[5px] text-[15px]">Camp Name  <span className="text-red-500">*</span></p>
                  <input
                    type="text"
                    name="camp_name"
                    id="camp_name"
                    placeholder="Camp Name"
                    className="sm:mb-[5px] h-[45px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                    value={currentPatient.camp_name}
                    onChange={onValueUpdate}
                  />
                  <p style={{ color: 'red' }}>{errors?.camp_name}</p>
                </div>

                : ""
            }


         





          </div>
          <div className="flex gap-[10px] mt-4">
          {localStorage.getItem("patient_form_mode") === "update" ? ( 
              <div className="w-1/2">
              <p className="mb-[5px] text-[15px]">
                Lead Type <span className="text-red-500">*</span>
              </p>
              <select
                placeholder="Source Type"
                id="lead_type"
                className="sm:mb-[5px] h-[45px] w-[200px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[15px] outline-none"
                name="lead_type"
                // onClick={(e)=> setcampName(e.target.value)}
                value={currentPatient.lead_type}
                onChange={onValueUpdate}
                readonly
              >
                <option value={""}>--select lead Type--</option>
                {leadtypeDropdown.map((option) => (
                  <option
                    key={option.id}
                    value={option.id}
                    className="capitalize"
                  >
                    {option.leadtype_name}
                  </option>
                ))}
              </select>
              <p style={{ color: "red" }}>{errors?.lead_type}</p>
            </div>
             ) : (

              <div className="w-1/2">
                <p className="mb-[5px] text-[15px]">
                Lead Type <span className="text-red-500">*</span>
            
                </p>
                <select
                  className="sm:mb-[5px] capitalize bg-white border border-gray-300 rounded-lg  w-full p-[11px] text-[17px] outline-none"
                  name="lead_type"
                  onChange={onValueUpdate}
                  value={currentPatient?.lead_type}
                  disabled={dataCame}
                >
                  <option value={""}>--select lead Type--</option>
                  {leadtypeDropdown.map((option) => (
                    <option
                      key={option.lead_name}
                      value={option.id}
                      className="capitalize"
                    >
                      {option.leadtype_name}
                    </option>
                  ))}
                </select>
                <p style={{ color: "red" }}>{errors?.lead_type}</p>
              </div>

            ) }


            <div className="w-1/2">
              <p className="mb-[5px] text-[15px]">Camp Name  </p>
              <input
                type="text"
                name="camp_name"
                id="camp_name"
                placeholder="camp_name"
                className="sm:mb-[5px] h-[45px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                value={currentPatient.camp_name}
                onChange={onValueUpdate}
              />
              <p style={{ color: 'red' }}>{errors?.camp_name}</p>
            </div>
            </div>



           




          
          <div>
            <p className="mt-5 text-black text-[14px] font-500">
              <label>Remarks</label>
            </p>
            <textarea
              className="mt-5 border-2 w-full border-black-500 p-1"
              id="remarks"
              name="remarks"
              rows="4"
              cols="50"
              onChange={onValueUpdate}
              value={currentPatient.remarks}
            ></textarea>
          </div>
          <div className="flex gap-[10px]">
            <button

              type="submit"
              className="bg-primary text-white px-[10px] py-[5px] rounded-[8px] mt-4 cursor-pointer "
            >
              SAVE
            </button>
            <div
              onClick={onClose}
              className="bg-primary text-white px-[10px] py-[5px] rounded-[8px] mt-4 cursor-pointer "
            >
              CLOSE
            </div>
          </div>
        </div>
      </form>

    </div>
  )
}

export default Addincomingform;
