import React, { useEffect, useState } from 'react'
import Arrow from '../../assets/images/arrow.svg'
import { Icon } from '@iconify/react';
import { branchListEndPoint, dashboardSourcetypeEndPoint, dashboardStatusEndPoint } from '../../service/api';
import { getAxios, postAxiosWithToken } from '../../service/apiservice';
import DatePicker1 from '../../components/DatePicker1';
import { convertToDate, convertToDateFilter, datetimetodate } from '../../utilities/datetime';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
 
 
export default function Dashboard() {
  const navigate = useNavigate();
  const [Statuslist, setStatuslist] = useState();
  const [patientList, setPatientList] = useState();
  const [patientTotalList, setPatientTotalList] = useState(0);
  const [totalLeadList, setTotalLeadList] = useState(0);
  
  const [Digitalmarketing, setDigitalmarketinglist] = useState();
  const [branchDropdown, setBranchDropdown] = useState([]);
  const [SelectedBranch, setSelectedBranch] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [DigitalTotalleads, setDigitalTotalleads] = useState();
  const [MarketingTotalleads, setMarketingTotalleads] = useState();
  const [ReferralTotalleads, setReferralTotalleads] = useState();
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  const [sourcelink, setSourceLink] = useState();

  
 
  const [patientstatus, Setpatientstatus] = useState({});
 
  // const [value, setValue] = useState({
  //   startDate:convertToDate(new Date(new Date().getFullYear(), new Date().getMonth(), 1),) ,
  //   endDate:convertToDate( new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0))
  // });

  const [value, setValue] = useState({
    startDate: convertToDateFilter(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 30)),
    endDate: convertToDateFilter(new Date())
  });


  const [total, setTotal] = useState(0);
 
  useEffect(() => {
 
    let body = {
      branch: localStorage.getItem('branch'),
      from_date: value.startDate || "",
      to_date: value.endDate || ""
    };
    getStatusData(body);
    getBranchDropdownData();

  }, [])

  useEffect(() => {
    setFromDate(value.startDate)
    setToDate(value.endDate)
  },[value])


  const getStatusData = async (body) => {
    var response = await postAxiosWithToken({
      url: dashboardStatusEndPoint,
      body: body
    })
    if (response !== null) {

     
      setStatuslist(response.data.list)
      setPatientList(response.data.patient_list)
      setTotalLeadList(response.data.total_lead_count)
      setPatientTotalList(response.data.total_patient_count)
      setDigitalmarketinglist(response.data.source_list)
      Setpatientstatus(response.data.patient_status)
      setDigitalTotalleads(response.data.digital)
      setMarketingTotalleads(response.data.nondigital)
      setReferralTotalleads(response.data.refdigital)
      setSourceLink(response.data.digitallink)
    }
  }
  // const totalCount = 0;
  const totalSourceCount = Statuslist ? Statuslist.reduce((total, item) => total + item.status_count, 0) : 0;
  const totalPatientCount = patientList ? patientList.reduce((total, item) => total + item.status_count, 0) : 0;
  const { today } = useSelector((state) => state.common);

 
  const getBranchDropdownData = async () => {
    var response = await getAxios({
      url: branchListEndPoint,
    });
 
    if (response !== null) {
      setBranchDropdown(response.data.list);
    }
  };
 
 
  const handleBranchFilter = async (e) => {

    localStorage.setItem('dasboardbranch', e.target.value)

    if(localStorage.getItem("branch") === "0"){
      var  branch= e.target.value;
      } else {
        var branch= localStorage.getItem("branch")
      };
 
   
    let branchBody = {
      branch: branch,
      from_date: fromDate,
      to_date: toDate
    };
    setSelectedBranch(e.target.value);
    getStatusData(branchBody);
  };
 
  const handleDateFilter = async (e ) => {
    let dateBody = {
      from_date: e.startDate,
      to_date: e.endDate,
      branch: SelectedBranch,
    };
    setFromDate(e.startDate);
    setToDate(e.endDate);
    getStatusData(dateBody);
    setValue(e);
  };

  





 
  return (
    <div>
      <div className='bg-[#F6F6FA] px-3'>
        <div className='md:flex md:justify-between py-[25px]'>
          <div>
          <p className="text-[#000] text-[20px] font-[500] leading-[25px] max-[639px]:text-center flex items-center">Welcome To SHIP</p>
          <p className="text-secondary font-[400] text-[10px] min-[360px]:text-[12px] min-[700px]:text-[14px] min-[360px]:leading-[20px] capitalize">{datetimetodate(today)}</p>
          </div>
         

     <div className='flex justify-end gap-[10px]'>
          {localStorage.getItem("branch") == "0" ?
         
          <div className='sm:flex sm:gap-[16px]'>
            <div className="flex gap-[10px] max-[768px]:mt-5">
              <div className="max-[520px]:mt-[5px] max-[520px]:w-full max-[520px]:mb-[50px]  ">
                <select
                  placeholder="Branch"
                  className="sm:mb-[5px] h-[40px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[9px] text-[15px] outline-none"
                  name="branch"
                  onChange={handleBranchFilter}
                  // onChange={()=>{handleBranchFilter();localStorage.setItem('dasboardbranch', e.target.value)}}
                >
                  <option value={""}>--select branch--</option>
                  {
                    branchDropdown.map((option) => (
                      <option key={option.id} value={option.id} className="capitalize">{option.branch_name}</option>
                    ))
                  }
                </select>
              </div>
            </div>
           
          </div>
         
          :""}
           <div className="!border !border-slate-800 !border-opacity-20 rounded-md mt-[20px] md:mt-[0px]">
              <DatePicker1 handleValueChange={handleDateFilter} value={value} />
            </div>
        </div>
       </div>

{localStorage.getItem("user_role_id") === "1" || localStorage.getItem("user_role_id") === "2" || localStorage.getItem("user_role_id") === "3" || localStorage.getItem("user_role_id") === "5" || localStorage.getItem("user_role_id") === "7"  ?
 
        <>
        
        <div className='py-[28px] px-[18px] bg-white rounded-xl shadow border border-gray-50'>
          <div className='flex justify-between'>
            <p className="text-indigo-950 text-xl font-semibold">Patient Status</p>
            <p className="text-[#425166] text-xl font-semibold font-['Inter']">Total OP : {patientTotalList}</p>
          </div>
          <div className='grid min-[430px]:grid-cols-2 sm:grid-cols-4 md:grid-cols-4 lg:grid-cols-6 xl:grid-cols-6 2xl:grid-cols-6 mt-[30px]'>
 
          {

            
              patientList?.map((item, index) => (
                <>
              {console.log(item,"test")}
                <div 
                onClick={() => {
                  if (index == 4) {
                    localStorage.setItem('admission', 1);
                  } else if (index == 1) {
                    localStorage.setItem('review_entry_type', 2);
                  } else if (index == 0) {
                    localStorage.setItem('entry_type', 1 );
                  }else if (index == 3){
                    localStorage.setItem('type_way', 4 ) ;
                  }
                 
                  // navigate("/" + item.link + "/" + fromDate  + '/' + toDate  )
                  navigate("/branch-procedure"+ "/" + fromDate  + '/' + toDate + "/"+ `${item.status_id}`);
                }}
                 className='bg-white rounded-xl border border-neutral-400 border-opacity-30 p-[15px] m-[10px] md:mr-[28px] mt-5 cursor-pointer hover:bg-[#c4d4e2]'>

                  <div className='flex justify-end'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 21 21"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" d="M14.5 13.5v-7h-7m7 0l-8 8"/></svg>
                    </div>
 
                  <div className='w-[35px] h-[35px] bg-[#5399DD40] rounded-full flex justify-center items-center mx-auto mt-[10px]'>
                 
                    <img src={item.status_icon} alt="no-icon" />
                  </div>
                  <div>
                    <p className="text-indigo-950 text-2xl font-semibold font-['Inter'] text-center mt-[14px]">{item.status_count}</p>
                    <p className="text-slate-600 text-[13px] font-medium font-['Inter'] le ading-snug text-center mt-[8px]">{item.status_name}</p>
                  </div>
                </div>
                </>
              ))
            }
           
          </div>
        </div>
       
       
        </>:""}
 
 {localStorage.getItem("user_role_id") === "1" || localStorage.getItem("user_role_id") === "2" || localStorage.getItem("user_role_id") === "3" || localStorage.getItem("user_role_id") === "4" || localStorage.getItem("user_role_id") === "6" ||  localStorage.getItem("user_role_id") === "7" ?


<div className='py-[28px] px-[18px] bg-white rounded-xl shadow border border-gray-50 mt-[40px] cursor-pointer'>
          <div className='flex justify-between'>
            <p className="text-indigo-950 text-xl font-semibold">Leads</p>
            <p className="text-[#425166] text-xl font-semibold font-['Inter']">Total Leads : {totalLeadList}</p>
          </div>
          <div className='grid min-[430px]:grid-cols-2 sm:grid-cols-4 md:grid-cols-4 lg:grid-cols-4 xl:grid-cols-4 2xl:grid-cols-8 mt-[30px] '>
 
            {
              Statuslist?.map((item) => (
                <>
                <div  onClick={() => { localStorage.setItem('status_id', item.status_id);navigate("/" + item.link + "/" + fromDate  + '/' + toDate)}} className='bg-white rounded-xl border border-neutral-400 border-opacity-30 p-[15px] m-[10px] md:mr-[28px] mt-5 hover:bg-[#c4d4e2]'>
   <div className='flex justify-end'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 21 21"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" d="M14.5 13.5v-7h-7m7 0l-8 8"/></svg>
                    </div>
                  <div className='w-[35px] h-[35px] bg-[#5399DD40] rounded-full flex justify-center items-center mx-auto mt-[10px]'>
                 
                    <img src={item.status_icon} alt="no-icon" />
                  </div>
                  <div>
                    <p className="text-indigo-950 text-2xl font-semibold font-['Inter'] text-center mt-[14px]">{item.status_count}</p>
                    <p className="text-slate-600 text-[13px] font-medium font-['Inter'] le ading-snug text-center mt-[8px]">{item.status_name}</p>
                  </div>
                </div>
                </>
              ))
            }
          </div>
        </div>


        :""}




        <div className='bg-white rounded-xl shadow p-[20px] my-[20px] '>
          <p className='text-indigo-950 text-base font-semibold'>Patient Source Type (New Op)</p>
          <div className='grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-3 my-[20px] gap-[50px]'>
            <div className='max-[450px]:!overflow-x-scroll'>
              <div className='bg-[aliceblue] rounded-[25px] shadow p-[15px] sm:p-[30px]'>
                <p className='text-blue-600 text-base font-medium text-center'>Digital Marketing Counts</p>
                <div className='mt-[50px]'>
                  <table className="w-full min-w-max table-auto text-left">
                    <tbody>
                      {Digitalmarketing?.map((item) => {
                        return <>
 
                          {item.source_displaytypes_id == 1 ?
 
 
                            <tr className='!bg-[#fff]'>
                              <td className="py-4 px-2">
                                <div style={{ backgroundColor: item.source_colour }} className='w-[22px] h-[9px] rounded-sm'>
 
                                </div>
                              </td>
                              <td className="py-4 px-2 text-black text-sm font-medium">
                                {item.source_name}
                              </td>
                              <td className="py-4 px-2 text-black text-sm font-medium">
                                <div
                               onClick={() => {localStorage.setItem('entry_type', 1 ) ; localStorage.setItem('source_id', item.source_id);localStorage.setItem('source_displaytypes_id', 1);navigate("/" + item.link + "/" + fromDate  + '/' + toDate)}}
                                className='flex justify-center cursor-pointer hover:text-white hover:bg-[#a2eef4] hover:rounded-[20px]'>
                                {item.source_count}
                                </div>
                              </td>
                            </tr>
 
                            : ""}
                        </>
                      })}

                      <tr onClick={() => {localStorage.setItem('entry_type', 1 ) ;localStorage.setItem('source_displaytypes_id', 1);navigate("/" + sourcelink + "/" + fromDate  + '/' + toDate)}} className='!bg-blue-600 rounded-[10px] cursor-pointer'>
                        <td></td>
                        <td className='py-2 px-2 text-white text-lg font-semibold leading-7'>Total</td>
                        <td className='py-2 px-2 text-white text-lg font-semibold leading-7'>{DigitalTotalleads}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className='max-[450px]:!overflow-x-scroll'>
              <div className='bg-[aliceblue] rounded-[25px] shadow p-[15px] sm:p-[30px]'>
                <p className='text-cyan-600 text-base font-medium text-center'> Marketing Counts</p>
                <div className='mt-[50px]'>
                  <table className="w-full min-w-max table-auto text-left">
                    <tbody>
                      {Digitalmarketing?.map((item) => (
                        <>
                          {item.source_displaytypes_id == 2 ?
                            <tr className='!bg-[#fff]'>
                              <td className="py-4 px-2">
                                <div style={{ backgroundColor: item.source_colour }} className='w-[22px] h-[9px] rounded-sm'>
                                </div>
                              </td>
                              <td className="py-4 px-2 text-black text-sm font-medium">
                                {item.source_name}
                              </td>
                              <td className="py-4 px-2 text-black text-sm font-medium">
                              <div
                               onClick={() => {localStorage.setItem('entry_type', 1 ) ; localStorage.setItem('source_id', item.source_id);localStorage.setItem('source_displaytypes_id', 2);navigate("/" + item.link + "/" + fromDate  + '/' + toDate)}}
                                className='flex justify-center cursor-pointer hover:text-white hover:bg-[#a2eef4] hover:rounded-[20px]'>
                                {item.source_count}
                                </div>
                              </td>
                            </tr>
                            : ""}
                        </>
                      ))}
                      <tr onClick={() => {localStorage.setItem('entry_type', 1 ) ;localStorage.setItem('source_displaytypes_id', 2);navigate("/" + sourcelink + "/" + fromDate  + '/' + toDate)}} className='!bg-[#009EAC] rounded-[10px] cursor-pointer'>
                        <td></td>
                        <td className='py-2 px-2 text-white text-lg font-semibold leading-7'>Total</td>
                        <td className='py-2 px-2 text-white text-lg font-semibold leading-7'>{MarketingTotalleads}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className='max-[450px]:!overflow-x-scroll'>
              <div className='bg-[aliceblue] rounded-[25px] shadow p-[15px] sm:p-[30px]'>
                <p className='text-cyan-800 text-base font-medium text-center'>Referral Counts</p>
                <div className='mt-[50px]'>
                  <table className="w-full min-w-max table-auto text-left">
                    <tbody>
                      {Digitalmarketing?.map((item) => (
                        <>
                          {item.source_displaytypes_id == 3 ?
                            <tr className='!bg-[#fff]'>
                              <td className="py-4 px-2">
                                <div style={{ backgroundColor: item.source_colour }} className='w-[22px] h-[9px] rounded-sm'>
                                </div>
                              </td>
                              <td className="py-4 px-2 text-black text-sm font-medium">
                                {item.source_name}
                              </td>
                              <td className="py-4 px-2 text-black text-sm font-medium">
                              <div
                               onClick={() => { localStorage.setItem('entry_type', 1 ) ;localStorage.setItem('source_id', item.source_id);localStorage.setItem('source_displaytypes_id', 3);navigate("/" + item.link + "/" + fromDate  + '/' + toDate)}}
                                className='flex justify-center cursor-pointer hover:text-white hover:bg-[#a2eef4]   hover:rounded-[20px]'>
                                {item.source_count}
                                </div>
                              </td>
                            </tr>
                            : ""}
                        </>
                      ))}
                      <tr onClick={() => {localStorage.setItem('entry_type', 1 ) ;localStorage.setItem('source_displaytypes_id', 3);navigate("/" + sourcelink + "/" + fromDate  + '/' + toDate)}} className='!bg-[#275582] rounded-[10px] cursor-pointer'>
                        <td></td>
                        <td className='py-2 px-2 text-white text-lg font-semibold leading-7'>Total</td>
                        <td className='py-2 px-2 text-white text-lg font-semibold leading-7'>{ReferralTotalleads}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>


      </div>
    </div>
  )
}