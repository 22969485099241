import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Layout from "../layout";
import Login from "../auth/login";
import { activeUserPermissionEndPoint } from "../service/api";
import { getAxios } from "../service/apiservice";
import { AddedAuthenticatedRoutes, AuthenticatedComponents } from "./authenticatedroutes";
import Page404 from "../pages/session/Page404";
import { useDispatch, useSelector } from "react-redux";
import { setIsLoading } from "../redux/commonslice";
import { Errorpage500 } from "../pages/session/500Page";
import LoadingSpinner from "../pages/session/loadingpage";
export default function Routers() {
    const navigate = useNavigate();
 
    const [AuthenticatedRoutes, setAuthenticatedRoutes] = useState([]);

    const [isAuthenticated, setIsAuthenticated] = useState(false);

    const { isLoading } = useSelector((state) => state.common);
    
    const dispatch = useDispatch();
 
        useEffect(() => {
        setIsAuthenticated(JSON.parse(localStorage.getItem('authenticated')))
        if (JSON.parse(localStorage.getItem('authenticated'))) {
            getMenuList()
        }

    }, [])

    const getMenuList = async () => {
        var response = await getAxios({
            url: activeUserPermissionEndPoint
        })
        
        if (response !== null) {
            if (response === 'ERR_NETWORK') {
                
                dispatch(setIsLoading(!isLoading))
                navigate('/500')
            } else {

                

                var tempList = []
                for (var item of response.data) {
                    
                    for (var subItem of item.menu_list) {
                        
                        for (var menuItem of AuthenticatedComponents) {
                            var lastPath = subItem.menu_path.split('/')[subItem.menu_path.split('/').length - 1]
                            if (lastPath.toString().toLowerCase() === menuItem.path.toString().toLowerCase()) {
                                var dictData = {
                                    path: subItem.menu_path,
                                    component: menuItem.component
                                }
                                tempList.push(dictData)

                            }
                        }
                    }
                }
                var mergedTempList = tempList.concat(AddedAuthenticatedRoutes)
                setAuthenticatedRoutes(mergedTempList)
                dispatch(setIsLoading(false))
            }
        }
        
    }
 
    return (
 
        <>
            

<Routes>
                <Route path="/" element={JSON.parse(localStorage.getItem('authenticated')) ? isLoading ? <LoadingSpinner /> : <Layout id="main" setAuthenticatedRoutes={setAuthenticatedRoutes} /> : <Login />}>
                    {
                        AuthenticatedRoutes.map((item) => (
                            <Route key={item.path} path={item.path} element={item.component} />
                        ))
                    }
                </Route>

                <Route path="/500" element={!JSON.parse(localStorage.getItem('authenticated')) ? <Navigate to={'/'} /> : isLoading ? <LoadingSpinner />  : <Errorpage500 /> } />
                <Route path="*" element={!JSON.parse(localStorage.getItem('authenticated')) ? <Navigate to={'/'} /> : isLoading ?  <LoadingSpinner /> : <Page404 /> } />
            </Routes>
        </>
 
    );
}