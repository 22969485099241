import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import {
  ReviewListEndPoint,
  ReviewtypeEndPoint,
  creatPatientReviewEndPoint,
  entryEndPoint,
  feeEndPoint,
  patientEndPoint,
} from "../../service/api";
import {
  getAxios,
  postAxiosWithToken,
  putAxiosWithToken,
} from "../../service/apiservice";

export default function Reviewform() {
  const [feesChange, setfeesChange] = useState("");



  const [entryId, setEntryId] = useState();
  const [reviewDropdown, setReviewDropdown] = useState([]);
  const [formMode, setFormMode] = useState("create");
  const [mobileNumber, setMobileNumber] = useState("");
  const [patientData, setPatientData] = useState(null);
  const [currentReview, setCurrentReview] = useState({});
  const [entryDropdown, setEntryDropdown] = useState([]);
  const [feeDropdown, setFeeDropdown] = useState([]);

  const [reviewValue, setreviewValue] = useState("");
  const [fileNoValue, setFileNoValue] = useState("");
 

  const navigate = useNavigate();

  const schema = yup
    .object({
      review_type: yup.string(),
      // review_day: yup.string(),
      review_date:yup.string(),
      remarks: yup.string(),
      entry_type: yup.string(),
      feetype_id: yup.string(),
      file_no: yup.string(),
      
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    values:
      formMode === "update"
        ? {
            review_type: currentReview?.review_type,
            // review_day: currentReview?.review_day,
            review_date: currentReview?.review_date,
            review_after: currentReview?.review_after,
            remarks: currentReview?.remarks,
            entry_type: currentReview?.entry_type,
            feetype_id: currentReview?.feetype_id,
            file_no: currentReview?.file_no,
            patient_id: patientData?.id,


          }
        : {
            review_type: "",
            // review_day: "",
            review_date: "",
            review_after: "",
            file_no: "",
            remarks: "",
            entry_type:entryId,
            feetype_id:"",
            patient_id: patientData?.id,
            status: 1,
            branch: localStorage.getItem("branch"),
          },
  });

  const onSubmit = async (data) => {
    if (localStorage.getItem("reviewList_form_mode") === "create") {
      createreviewpatient(data);
    } else {
      updateStaff(data);
    }
  };

  useEffect(() => {
    getreviewDropdownList();

    setFormMode(localStorage.getItem("reviewList_form_mode"));

    if (localStorage.getItem("reviewList_form_mode") === "update") {
      getreviewListDetails();
    }
  }, []);

  useEffect(()=>{
    getentryTypedownData();
  },[])

  const createreviewpatient = async (body) => {

    body['review_date']=body.review_date === ""? null:body.review_date 
    var response = await postAxiosWithToken({
      url: creatPatientReviewEndPoint,
      body: body,
    });

    if (response != null) {
      toast.success(response.message);
      navigate("/review/review-list");
    }
  };

  const updateStaff = async (body) => {
    body['review_date']=body.review_date === ""? null:body.review_date 
    var response = await putAxiosWithToken({
      url: creatPatientReviewEndPoint +localStorage.getItem("reviewList_form_id") + "/",
      body: body,
    });

    if (response != null) {
      // clearData()
      // localStorage.removeItem('staff_form_id')
      // localStorage.setItem('staff_form_mode', 'create')
      toast.success(response.message);
      navigate('/review/review-list')
    }
  };

  const getreviewListDetails = async () => {
    var id = localStorage.getItem("reviewList_form_id");

    var response = await getAxios({
      url: creatPatientReviewEndPoint + id + "/",
    });

    if (response !== null) {
      var tempDetails = {
        // review_day: response.data.review_list?.review_day,
        review_date: response.data.review_list?.review_date,
        review_after: response.data.review_list?.review_after,
        remarks: response.data.patient_list?.remarks,
        patient_id: response.data.patient_list?.patient_id,
        entry_type: response.data.review_list?.entry_type,
        feetype_id: response.data.review_list?.feetype_id,
        review_type: response.data.review_list?.review_type,
        file_no: response.data.review_list?.file_no,
      };
      setCurrentReview(tempDetails);
      setPatientData(response.data.patient_list);
      getfeeTypedownData(response?.data?.review_list?.entry_type);
      setfeesChange(response?.data?.review_list?.feetype_id);
      // setEntryId(response?.data?.review_list?.entry_type);
      setreviewValue(response.data.review_list?.review_type);
      
    }
  };

  

  const getreviewDropdownList = async () => {
    var response = await getAxios({
      url: ReviewtypeEndPoint,
    });

    if (response !== null) {
      setReviewDropdown(response.data.list);
    }
  };

  const getentryTypedownData = async () => {
  
    var response = await getAxios({
      url: entryEndPoint,
    });

    if (response !== null) {  
      setEntryDropdown(response.data.list);
    
  };
  };
  // useEffect(()=>{
  //   getfeeTypedownData();
  // },[entryId])
 

  const getfeeTypedownData = async (id) => {

  
    // var id = entryId
    if(id  !== ""){
    var response = await getAxios({
      url: feeEndPoint + id + "/",
    });
  };


    if (response !== null) {
      setFeeDropdown(response?.data.list);
    }
  };






  const handleSearch = async () => {
    var response = await getAxios({
      url: ReviewListEndPoint + mobileNumber + "/" + 1 +"/",
    });

    if (response !== null) {
      setPatientData(response.data.list.patient_details);
      setEntryId(response.data?.list?.patient_details?.patient_entry_type);
      getfeeTypedownData(response.data?.list?.patient_details?.patient_entry_type);
      setFileNoValue(response.data?.list?.patient_details?.file_no)
    }
  };
  return (
    <div className="bg-[#F5F5F7] p-[12px] sm:p-[20px]">
      <p className="text-zinc-900 text-[18px] sm:text-[24px] font-medium leading-7">
      Add In-Patient Review List
      </p>

      <div className="bg-[#fff] rounded-lg p-[12px] sm:p-[24px] my-[20px]">
        {localStorage.getItem("reviewList_form_mode") === "update" ? "" :  <>
            <p className="text-gray-900 text-[19px] font-semibold mb-2">
            Patient Mobile Number | File No
            </p>
            
            <div className="col-span-2 flex justify-between items-center px-[10px] py-[8px] bg-white ">
              <div className="flex gap-[16px]">
                <input
                  type="text"
                  id="mobileNumber"
                  value={mobileNumber}
                  onChange={(e) => setMobileNumber(e.target.value)}
                  className="outline-none  text-base font-medium leading-none w-full rounded-md border border-slate-800 border-opacity-20 p-4 text-black"
                  placeholder="File no, Mobile No"
                />

                <button
                  onClick={handleSearch}
                  className="w-[200px] capitalize bg-primary text-white rounded-lg p-[10px] h-[50px] font-[500] text-[17px] outline-none border-none"
                >
                  <span className="flex justify-center items-center gap-[10px] ">
                    <Icon
                      className="my-auto"
                      icon="iconamoon:search-light"
                      color="white"
                    />
                    Search
                  </span>
                </button>
              </div>
            </div>
            
          </>}

        <p className="text-gray-900 text-[19px] font-semibold mt-[20px] sm:mt-[30px]">
        Review Details
        </p>

        <div className="grid sm:grid-cols-1	md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-3 mt-[15px] gap-[25px]">
          <div className="flex justify-between">
            <div>
              
              <p className="text-neutral-400 leading-[25px] text-[14px] sm:text-[16px] font-normal mt-2">
                Patient ID
              </p>
              <p className="text-neutral-400 leading-[25px] text-[14px] sm:text-[16px] font-normal mt-2">
                Patient Name
              </p>
              <p className="text-neutral-400 leading-[25px] text-[14px] sm:text-[16px] font-normal mt-2">
                Spouse Name
              </p>

              <p className="text-neutral-400 leading-[25px] text-[14px] sm:text-[16px] font-normal mt-2">
                Mobile Number
              </p>
              <p className="text-neutral-400 leading-[25px] text-[14px] sm:text-[16px] font-normal mt-2">
                E-Mail
              </p>
            </div>
            <div>
             
              <p className="text-black leading-[25px] text-[14px] sm:text-[16px] font-normal mt-2">
               {patientData?.patient_no!==""?patientData?.patient_no:"Nill"}
              </p>
              <p className="text-black leading-[25px] text-[14px] sm:text-[16px] font-normal mt-2">
                {patientData?.name!==""?patientData?.name:"Nill"}
              </p>
              <p className="text-black leading-[25px] text-[14px] sm:text-[16px] font-normal mt-2">
                {patientData?.spouse_name!==""?patientData?.spouse_name:"Nill"}
              </p>
 
              <p className="text-black leading-[25px] text-[14px] sm:text-[16px] font-normal mt-2">
                {patientData?.phone!==""?patientData?.phone:"Nill"}
              </p>
              <p className="text-black leading-[25px] text-[14px] sm:text-[16px] font-normal mt-2">
                {patientData?.email!==""?patientData?.email:"Nill"}
              </p>
              {/* <p className="text-black leading-[25px] text-[14px] sm:text-[16px] font-normal mt-2">
                {patientData?.dob!==""?patientData?.dob:"Nill"}
              </p> */}
            </div>
          </div>
          <div className="xl:col-span-2">
            <p className="text-gray-900 text-[19px] font-semibold">
              Address Information
            </p>
            <p className="text-black text-base font-normal">
              {patientData?.address}
            </p>
          </div>
        </div>
        <div className="border border-gray-300 mt-[25px]"></div>

        <p className=" text-gray-900 text-[19px] font-semibold mt-[20px] sm:mt-[40px]">
        Review Details
        </p>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="grid sm:grid-cols-2	md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2s mt-[24px] gap-[20px]">

        
       
            <div className="sm:my-[5px]">
              <p className="mb-[5px] text-[15px]">
                Entry Type <span className="text-red-500">*</span>
              </p>
              <select
                placeholder="Select Entry Type"
                className="sm:mb-[5px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
                name="entry_type"
                {...register("entry_type")}
                value={entryId}
                onChange={(e) => { setEntryId(e.target.value);}}
                
              >
            
                <option value={""}>--Select Entry Type--</option>
                {entryDropdown?.map((option) => (
                  <option
                    className="capitalize"
                    key={option.id}
                    value={option.id}
                  >
                    {option.type_name}
                  </option>
                ))}
              </select>
              
            </div>
            


            <div className="sm:my-[5px]">
              <p className="mb-[5px] text-[15px]">
                Fee Type <span className="text-red-500">*</span>
              </p>
              <select
                placeholder="Select Fee Type"
                className="sm:mb-[5px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
                name="feetype_id"
                {...register("feetype_id")}
                value={feesChange}
                onChange={(e) => {setfeesChange(e.target.value)}}
              >
                <option value={""}>--Select Treatment Type--</option>
                {feeDropdown?.map((option) => (
                  <option
                    className="capitalize"
                    key={option.id}
                    value={option.id}
                  >
                    {option.feestype_name}
                  </option>
                ))}
              </select>
              {/* <p className="text-red-500 font-[400] text-[13px]">
                    {errors.feetype_id?.message}
                    </p> */}
            </div>
            <div className="my-[5px]">
              <p className="mb-[5px] text-[15px]">
              File No <span className="text-red-500"></span>
              </p>
              <input
                type="text"
                placeholder="file_no"
                className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                name="file_no"
                id="file_no"
                {...register("file_no")}
                value={fileNoValue}
                onChange={(e)=>setFileNoValue(e.target.value)}
              />
              {/* <p className="text-red-500 font-[400] text-[13px]">
                {errors.file_no?.message}
            </p> */}
            </div>


            <div className="sm:my-[5px]">
              <p className="mb-[5px] text-[15px]">
                Review Type <span className="text-red-500">*</span>
              </p>
              <select
                placeholder="Select Treatment Type"
                className="sm:mb-[5px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
                name="review_type"
                {...register("review_type")}
                value={reviewValue}
                onChange={(e)=>setreviewValue(e.target.value)}
              >
                <option value={""}>--Select Review Type--</option>
                {reviewDropdown.map((option) => (
                  <option
                    className="capitalize"
                    key={option.id}
                    value={option.id}
                  >
                    {option.review_name}
                  </option>
                ))}
              </select>
              {/* <p className="text-red-500 font-[400] text-[13px]">
                    {errors.feetype_id?.message}
                    </p> */}
            </div>
            {/* <div className="my-[5px]">
              <p className="mb-[5px] text-[15px]">
                Review Day <span className="text-red-500">*</span>
              </p>
              <input
                type="text"
                placeholder="Review Day"
                className="sm:mb-[5px]  border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                name="review_day"
                id="review_day"
                {...register("review_day")}
              />
              <p className="text-red-500 font-[400] text-[13px]">
                {errors.file_no?.message}
            </p>
            </div> */}

            <div className="my-[5px]">
              <p className="mb-[5px] text-[15px]">
                Next Review Date 
              </p>
              <input
                type="date"
                placeholder="dd/mm/yyyy"
                className="sm:mb-[5px] h-[45px] w-[200px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                id="review_date"
                name="review_date"
                {...register("review_date")}
                // onChange={(e) => setDateofBirth(e.target.value)}
              />
              <p className="text-red-500 font-[400] text-[13px]">
                {/* {errors.proceduredate?.message} */}
              </p>
            </div>

            <div className="my-[5px]">
              <p className="mb-[5px] text-[15px]">
                Review After<span className="text-red-500">*</span>
              </p>
              <input
                type="text"
                placeholder=""
                className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                name="review_after"
                {...register("review_after")}
                id="review_after"
              />
              {/* <p className="text-red-500 font-[400] text-[13px]">
                {errors.reason?.message}
            </p> */}
            </div>
          </div>
          <div className="mt-[40px] grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-3">
            <div className="col-span-2 bg-white rounded-md">
              <p className="mb-[5px] text-[15px]">Remarks</p>
              <textarea
                class="mt-5 border-2 w-full border-black-500 p-4"
                id="remarks"
                name="remarks"
                rows="4"
                cols="50"
                {...register("remarks")}
              ></textarea>
            </div>
          </div>

          <div className="flex justify-end gap-[30px] mt-5">
            <button
              onClick={(e) => {
                e.preventDefault();
                reset();
              }}
              className="w-[150px] capitalize bg-white text-black rounded-lg p-[10px] h-fit font-[500] text-[17px] outline-none border border-black "
            >
              Reset
            </button>
            <button
              type="submit"
              className="w-[150px] capitalize bg-primary text-white rounded-lg p-[10px] h-fit font-[500] text-[17px] outline-none border-none"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
