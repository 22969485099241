import React, { useEffect, useState } from "react";
import {
  PatientreviewListEndPoint,
  ReviewtypeEndPoint,
  branchListEndPoint,
  creatPatientReviewEndPoint,
  entryEndPoint,
  feeEndPoint,
  leadAgentListEndPoint,
} from "../../service/api";
import {
  deleteAxiosWithToken,
  getAxios,
  postAxiosWithToken,
} from "../../service/apiservice";
import { useNavigate, useParams } from "react-router-dom";
import CustomePagination from "../../components/CustomPagination";
import Searchbar from "../../components/SearchBar";
import DatePicker1 from "../../components/DatePicker1";
import { DeleteButton, EditButton, ViewButton } from "../../components/Actions";
import { convertToDate } from "../../utilities/datetime";
import { convertToTime } from "../../utilities/time";
import Popup from "../../components/Popup";
import toast from "react-hot-toast";
import Viewreview from "./Viewreview";
import Model from "../../components/Model";
import { useSelector } from "react-redux";
import CustomPopover from "../../components/Popover";
import { Popover } from "@headlessui/react";

function Review() {
  const [perPage, setPerPage] = useState(10);
  const [allleadsTableList, setAllreviewTableList] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [currentReview, setCurrentReview] = useState({});
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [branchDropdown, setBranchDropdown] = useState([]);
  const [entryDropdown, setEntryDropdown] = useState([]);
  const [feeDropdown, setFeeDropdown] = useState([]);
  const [reviewDropdown, setReviewDropdown] = useState([]);
  const [isviewOpen, setIsviewOpen] = useState(false);
  const [totalitems, setTotalitems] = useState({});
  const [agentList, setAgentList] = useState([]);

  
  const { action } = useSelector((state) => state.common);

  const {fromDate,toDate} = useParams()
  const selectedBranchFromLocalStorage = localStorage.getItem('dasboardbranch');


  const [body, setBody] = useState({
    search: "",
    from_date: fromDate||"",
    to_date:toDate|| "",
    page: 1,
    items_per_page: 10,
    branch:  selectedBranchFromLocalStorage || localStorage.getItem("branch") ,
    entry_type: localStorage.getItem("review_entry_type") || "",
    feetype_id: "",
    review_type: "",
  });
  const [value, setValue] = useState({
    startDate: fromDate,
    endDate: toDate,
  });
  const navigate = useNavigate();

  useEffect(() => {
    getreviewListData();
    getfeeTypedownData();
  }, [body]);

  function closeDeleteModal() {
    setIsDeleteOpen(false);
  }

  function closeViewModal() {
    setIsviewOpen(false);
  }

  const getreviewListData = async () => {
    var response = await postAxiosWithToken({
      url: PatientreviewListEndPoint,
      body: body,
    });

    if (response !== null) {
      setAllreviewTableList(response.data.list);
      setTotalPages(response.data.total_pages);
      setTotalitems(response.data);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getBranchDropdownData();
    getentryTypedownData();
    getreviewDropdownList();
    getAgentListData();
  }, []);

  const getAgentListData = async () => {
    var response = await getAxios({
      url: leadAgentListEndPoint
    })

    if (response != null) {
      setAgentList(response.data.list)
    }
  }

  const getBranchDropdownData = async () => {
    var response = await getAxios({
      url: branchListEndPoint,
    });

    if (response !== null) {
      setBranchDropdown(response.data.list);
    }
  };
  const getreviewDropdownList = async () => {
    var response = await getAxios({
      url: ReviewtypeEndPoint,
    });

    if (response !== null) {
      setReviewDropdown(response.data.list);
    }
  };

  const deleteRview = async () => {
    var response = await deleteAxiosWithToken({
      url: creatPatientReviewEndPoint + currentReview.id + "/",
    });

    if (response !== null) {
      getreviewListData();
      closeDeleteModal();
      toast.success(response.message);
    }
  };

  const getentryTypedownData = async () => {
    var response = await getAxios({
      url: entryEndPoint,
    });

    if (response !== null) {
    
      setEntryDropdown(response.data.list);
    }
  };

  const getfeeTypedownData = async () => {
    let response = null; // Initialize response variable

    if (body.entry_type !== "") {
      try {
        response = await getAxios({
          url: feeEndPoint + body.entry_type + "/",
        });
        

        if (response !== null) {
          setFeeDropdown(response.data.list);
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  // Filter

  const handleDateFilter = async (e) => {
   
    if (e.startDate === null && e.endDate === null) {
      setBody((prevState) => ({
        ...prevState,
        from_date: "",
        to_date: "",
        page: 1,
      }));
    } else {
      setBody((prevState) => ({
        ...prevState,
        from_date: e.startDate,
        to_date: e.endDate,
        page: 1,
      }));
    }

    setValue(e);
  };

  const searchHandler = (e) => {
    setBody((prevState) => ({
      ...prevState,
      search: e.target.value,
      page: 1,
    }));
  };

  const pageHandler = (value) => {
    setBody((prevState) => ({
      ...prevState,
      page: value,
    }));
  };

  const entriesHandler = (value) => {
    setBody((prevState) => ({
      ...prevState,
      items_per_page: value,
      page: 1,
    }));
  };
  return (
    <div>
      <div className="flex gap-[20px] items-center h-[40px] justify-between mb-[24px] mt-[24px] max-[425px]:block max-[425px]:mb-[50px] ">
        <div>
          <p className="sm:mb-[5px] bg-white border-none rounded-lg w-full p-[5px]  text-[#1D1D1F] text-[24px] font-[500px] outline-none">
            Review List
          </p>
        </div>

        <div className="flex gap-[10px] max-[520px]:block max-[520px]:mt-5">
          <div className="h-[40px] w-[200px] max-[520px]:mt-[5px] max-[520px]:w-full    ">
            <Searchbar value={body.search} handleChange={searchHandler} />
          </div>

          
          <div className="flex gap-[10px] max-[520px]:mt-5 ">
            <div className="max-[520px]:mt-[5px] max-[520px]:w-full max-[520px]:mb-[50px]  ">
              {localStorage.getItem("branch") == 0 ? (
                <select
                  placeholder="Branch"
                  className="sm:mb-[5px] h-[40px] w-[200px] capitalize bg-white border border-gray-300 rounded-lg p-[11px] text-[15px] outline-none"
                  name="branch"
                  value={body.branch}
                  onChange={(e) => {
                    setBody((prevState) => ({
                      ...prevState,
                      branch: e.target.value,
                      page: 1,
                    }));
                  }}
                >
                  <option value={""}>--select branch--</option>

                  {branchDropdown.map((option) => (
                    <option
                      key={option.id}
                      value={option.id}
                      className="capitalize"
                    >
                      {option.branch_name}
                    </option>
                  ))}
                </select>
              ) : (
                ""
              )}
            </div>
          </div>


          <div className="!border !border-slate-800 !border-opacity-20 rounded-md mt-[20px] md:mt-[0px]">
            <DatePicker1 handleValueChange={handleDateFilter} value={value} />
          </div>

          

         

        </div>
      </div>

      <div className="flex gap-[20px] items-center h-[40px] justify-end mb-[24px] mt-[24px] max-[425px]:block max-[425px]:mb-[50px] ">
        <div className="max-[520px]:mt-[5px] max-[520px]:w-full max-[520px]:mb-[50px]  ">
          <select
            placeholder="Select Entry Type"
            className="sm:mb-[5px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
            name="entry_type"
            value={body.entry_type}
            onChange={(e) => {
              setBody((prevState) => ({
                ...prevState,
                entry_type: e.target.value,
                page: 1,
              }));
              // getfeeTypedownData(e.target.value)
            }}
          >
            <option value={""}>--Select Entry Type--</option>
            {entryDropdown?.map((option) => (
              <option className="capitalize" key={option.id} value={option.id}>
                {option.type_name}
              </option>
            ))}
          </select>
        </div>

        <div className="max-[520px]:mt-[5px] max-[520px]:w-full max-[520px]:mb-[50px]  ">
          <select
            placeholder="Select Fee Type"
            className="sm:mb-[5px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
            name="feetype_id"
            onChange={(e) => {
              setBody((prevState) => ({
                ...prevState,
                feetype_id: e.target.value,
                page: 1,
              }));
            }}
          >
            <option value={""}>--Select Fee Type--</option>
            {feeDropdown?.map((option) => (
              <option className="capitalize" key={option.id} value={option.id}>
                {option.feestype_name}
              </option>
            ))}
          </select>
        </div>
        <div className="max-[520px]:mt-[5px] max-[520px]:w-full max-[520px]:mb-[50px]  ">
          <select
            placeholder="Select Treatment Type"
            className="sm:mb-[5px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
            name="review_type"
            onChange={(e) => {
              setBody((prevState) => ({
                ...prevState,
                review_type: e.target.value,
                page: 1,
              }));
            }}
          >
            <option value={""}>--Select Review Type--</option>
            {reviewDropdown.map((option) => (
              <option className="capitalize" key={option.id} value={option.id}>
                {option.review_name}
              </option>
            ))}
          </select>
        </div>

        <div>
            {/* {localStorage.getItem("branch") != 0 ? ( */}
              <button
               

                onClick={() => {
                  if (action.addPermit) {
                    localStorage.setItem("reviewList_form_mode", "create");
                  navigate("/review-list/review-form");
                  } else {
                    toast.error("You don't have permission for create");
                  }
                }}
                className="w-[200px] capitalize bg-primary text-white rounded-lg p-[10px] h-fit font-[500] text-[17px] outline-none border-none"
              >
                <span className="flex justify-center items-center gap-[10px] ">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z"
                      fill="white"
                    />
                  </svg>
                  Create Review
                </span>
              </button>
            {/* ) : (
              ""
            )} */}
          </div>
      </div>

      <div className="w-full my-[10px] mb-[50px] overflow-x-scroll rounded-lg ">
        <table className="w-full">
          <thead className="border bg-secondary text-white">
            <tr>
              <td className="p-[10px]">S.No</td>
              {localStorage.getItem('calllog') == 1 ?
              <>
              <td className="p-[10px] min-w-[100px]">Call Action</td>
              <td className="p-[10px] min-w-[150px]">Call Count</td></>:""}
              <td className="p-[10px] min-w-[200px]">File No</td>

              <td className="p-[10px] min-w-[200px]">Name</td>
              <td className="p-[10px] min-w-[200px]">Spouse Name</td>

              <td className="p-[10px] min-w-[200px]">Mobile No</td>

              <td className="p-[10px] min-w-[200px]">Branch</td>

              <td className="p-[10px] min-w-[200px]">Review Type</td>

              <td className="p-[10px] min-w-[200px]">Entry Type</td>

              <td className="p-[10px] min-w-[200px]">Fee Type</td>
              <td className="p-[10px] min-w-[200px]">Source Type</td>
              <td className="p-[10px] min-w-[200px]">Lead Type</td>
              <td className="p-[10px] min-w-[200px]">Created by</td>

              <td className="p-[10px] min-w-[200px]">Review Date</td>


              <td className="p-[10px] min-w-[200px]">Created Date</td>
              <td className="p-[10px] min-w-[200px]">Last Update Date</td>
              <td className="p-[10px] min-w-[200px]">Remarks</td>

              <td className="p-[10px] min-w-[100px] sticky right-0 bg-secondary">Action</td>
              
            </tr>
          </thead>

          <tbody>
            {isLoading ? (
              <tr className="text-center w-full">
                <td className="text-center border" colSpan={5}>
                  Loading...
                </td>
              </tr>
            ) : allleadsTableList.length === 0 ? (
              <tr>
                <td className="text-center border" colSpan={5}>
                  No Data
                </td>
              </tr>
            ) : (
              allleadsTableList.map((item, index) => (
                <tr className="border">
                   <td className="p-[10px]">{item.sno}</td>

                   {localStorage.getItem('calllog') == 1 ?
                  <>
                  
                  <td className="p-[10px] capitalize">
                  <div className='w-[150px]'>
                    <Popover
                      placement="bottom-end"
                      animate={{
                        mount: { scale: 1, y: 0 },
                        unmount: { scale: 0, y: 25 },
                      }}
                    ></Popover>
                    <CustomPopover options={agentList} customerMobile={item.patient_mobile} leadId={item.id} />
                  </div>
                </td>

                <td className="p-[10px]">{item.call_count}</td>
                  
                  
                </>
                  
                  :""}
                   <td className="p-[10px]">{item.file_no}</td>
                   <td className="p-[10px] capitalize">{item.patient_name}</td>
                  <td className="p-[10px] capitalize">{item.spouse_name}</td>

                  <td className="p-[10px]">{item.patient_mobile}</td>

                  <td className="p-[10px]">{item.branch_name}</td>
                  <td className="p-[10px] capitalize">{item.review_type}</td>
                  <td className="p-[10px] capitalize">{item.entry_typename}</td>
                  <td className="p-[10px] capitalize">{item.feestype_name}</td>
                  <td className="p-[10px] capitalize">{item.source_types}</td>
                  <td className="p-[10px] capitalize">{item.lead_type}</td>
                  <td className="p-[10px] capitalize">{item.created_by}</td>
                  <td className="p-[10px] capitalize">{item.review_date ? convertToDate(item.review_date) : '0000-00-00'}</td>


                  <td className="p-[10px]">
                      <p className="text-[#262831] text-[14px] fony-[500]">
                        {convertToDate(item.created_at ? item.created_at : '--')}
                      
                      </p>
                      <p className="text-[#BCBDBD] text-[12px] fony-[500]">
                        {convertToTime(item.created_at ? item.created_at : '--')}
                      </p>
                    </td>

                    <td className="p-[10px]">
                      <p className="text-[#262831] text-[14px] fony-[500]">
                        {convertToDate(item.modified_at ? item.modified_at : '--')}
                      
                      </p>
                      <p className="text-[#BCBDBD] text-[12px] fony-[500]">
                        {convertToTime(item.modified_at ? item.modified_at : '--')}
                      </p>
                    </td>

                    <td className="p-[10px] capitalize">{item.remarks}</td>


                  <td className="p-[10px] sticky right-0 bg-white">
                    <div className="flex gap-3">
                      
                      <div
                        onClick={() => {
                          if (action.editPermit) {
                            setCurrentReview(item);
                          localStorage.setItem(
                            "reviewList_form_mode",
                            "update"
                          );

                          localStorage.setItem(
                            "reviewList_form_id",
                            item.id.toString()
                          );

                          navigate("/review-list/review-form");
                          } else{
                            toast.error("You don't have permission for update")
                          }
                          
                        }}
                        className="cursor-pointer hover:scale-125"
                      >
                        <EditButton />
                      </div>

                      <div
                       
                        onClick={() => {
                          if (action.deletePermit) {
                            setCurrentReview(item);
                          setIsDeleteOpen(true);
                          } else {
                            toast.error("You don't have permission for delete");
                          }
                        }}
                        className="cursor-pointer hover:scale-125"
                      >
                        <DeleteButton />
                      </div>
                    </div>
                  </td>

                  <td className="p-[10px] capitalize">
                    {item.created_by_name}
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
      <div className="mb-[50px]">
        <CustomePagination
          totalPages={totalPages}
          currentPage={body.page}
          setCurrentPage={pageHandler}
          entries={body.items_per_page}
          entriesHandler={entriesHandler}
          totalitmes={totalitems.total_items}
          />
      </div>

      

      

      

      <div>
        <Popup
          title={"Delete Review"}
          setIsOpen={setIsDeleteOpen}
          isOpen={isDeleteOpen}
          closeModal={closeDeleteModal}
        >
          <div className="mt-[10px]">
            <p className="font-400 text-[15px]">
              Do you want to delete the {allleadsTableList?.patient_id}
              Review?
            </p>
            <div className="mt-[10px] flex gap-3 justify-end items-center">
              <button
                onClick={() => closeDeleteModal()}
                className="border-none outline-none px-[10px] py-[5px] w-fit rounded-lg text-white bg-primary"
              >
                Close
              </button>
              <button
                type="submit"
                onClick={() => {
                  deleteRview();
                }}
                className="border-none outline-none px-[10px] py-[5px] w-fit rounded-lg text-white bg-red-600"
              >
                Delete
              </button>
            </div>
          </div>
        </Popup>
      </div>
    </div>
  );
}

export default Review;
